export default function Balance() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="19" viewBox="0 0 16 19">
      <path fill="currentColor" fillRule="evenodd"
        d="M5.92 3.44c0-.6.4-1.18 1.21-1.67.81-.5 2.02-.79 3.33-.79 1.3 0 2.42.3 3.32.79.8.49 1.21 1.08 1.21 1.67 0
          .59-.4 1.17-1.2 1.67-.91.49-2.02.78-3.33.78s-2.52-.3-3.33-.78c-.8-.5-1.2-1.08-1.2-1.67Zm-1 0c0-.99.7-1.97
          1.71-2.56A8.9 8.9 0 0 1 10.46 0c1.4 0 2.82.4 3.83.98 1 .5 1.71 1.38 1.71 2.46v11.58c0 .99-.7 1.97-1.71
          2.56a7.88 7.88 0 0 1-3.83.98l-1.61-.2.8-.98.8.1a6.9 6.9 0 0 0 3.33-.79c.8-.49
          1.21-1.08 1.21-1.67v-1.76l-.7.58c-1.01.5-2.22.79-3.53.89a6.35 6.19 0 0 0 0-.98 6.8 6.8 0 0 0 3.02-.79c.8-.49
          1.21-1.08 1.21-1.67V9.33l-.7.59a7.56 7.36 0 0 1-4.34.88l-.8-1.08 1.3.1a6.9 6.9 0 0 0 3.33-.79c.8-.49 1.21-1.08
          1.21-1.67V5.4l-.7.59a9 9 0 0 1-3.83.88 7.6 7.6 0 0 1-3.83-.98l-.7-.49v1.96l.1.6-1.11-.2Z"
      />
      <path fill="currentColor" fillRule="evenodd"
        d="M4.51 18.95a5.24 5.1 0 1 0 0-10.11 5.24 5.1 0 0 0 0 10.11zm-1.6-7.36v5h4.02v-1.47h-.2c0
          .79-.6.79-1.2.79H4.2V10.9l-2.01.2v.39l.8.1z"
      />
    </svg>
  );
}
