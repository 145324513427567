import Section from "../component/section";
import ButtonLink from "../component/button-link";
import ImageAndTextCard from "../component/image-and-text-card";
import Row from "../component/row";
import {EmptyPackage, Loader} from "../component/icons";
import {useEffect, useState} from "react";
import useCRUD from "../hook/useCRUD";
import ProductCard from "../component/product-card";
import ErrorModal from "../component/error-modal";
import IconAndText from "../component/icon-and-text";
import BrandSlider from "../component/brand-slider";
import {NavLink} from "react-router-dom";
import {isExternalLink, toRelativePath} from "../functions";
import useShowCredits from "../hook/useShowCredits";

export default function Start() {

  const crud = useCRUD();

  const [starredPromotions, setStarredPromotions] = useState([]);
  const [topSeller, setTopSeller] = useState([]);
  const [welcomePanels, setWelcomePanels] = useState([]);
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState([]);
  const showCredits = useShowCredits();

  useEffect(() => {
    setLoading(true);
    const requests = [crud.data.bulk.read({entity: 'product', filter: crud.filter.equals('is_top_seller', 1)})];
    if (showCredits) {
      requests.push(crud.data.bulk.read({entity: 'promotion', filter: crud.filter.equals('is_starred', 1)}));
      requests.push(crud.data.bulk.read({entity: 'welcome_panel', filter: crud.filter.equals('visible', 1)}));
    }
    Promise.all(requests)
      .then(([productResult, promotionResult, welcomePanelResult]) => {
        const expandRequests = [crud.expand(productResult.items, ['brand', 'product_type', 'image:file'])];
        if (showCredits) {
          expandRequests.push(crud.expand(promotionResult.items, ['image:file']));
          expandRequests.push(crud.expand(welcomePanelResult.items, ['image:file']));
        }
        Promise.all(expandRequests)
          .then(([expandedProduct, expandedPromotions, expandedWelcomePanels]) => {
            setTopSeller(expandedProduct);
            setStarredPromotions(expandedPromotions ?? []);
            setWelcomePanels(expandedWelcomePanels ?? []);
          });
      })
      .catch(errors => setErrors(errors))
      .finally(() => setLoading(false));
  }, [crud, showCredits]);

  return (
    <>
      {
        showCredits &&
        <Section>
          {loading && <IconAndText className="mt-1-75" icon={<Loader/>} text="Laden…"/>}
          {
            !loading && welcomePanels.length > 0 &&
            <Row className="mt-1" gap="1" columns={{default: 1}}>
              {
                welcomePanels.map((welcomePanel, index) =>
                  <div key={index}>
                    {
                      welcomePanel.link && isExternalLink(welcomePanel.link) &&
                      <a href={welcomePanel.link} target="_blank" rel="noopener noreferrer">
                        <img src={welcomePanel.image.url} alt="Willkommen im Landewyck-Online-Shop"/>
                      </a>
                    }
                    {
                      welcomePanel.link && !isExternalLink(welcomePanel.link) &&
                      <NavLink to={toRelativePath(welcomePanel.link)}>
                        <img src={welcomePanel.image.url} alt="Willkommen im Landewyck-Online-Shop"/>
                      </NavLink>
                    }
                    {
                      !welcomePanel.link &&
                      <img src={welcomePanel.image.url} alt="Willkommen im Landewyck-Online-Shop"/>
                    }
                  </div>
                )
              }
            </Row>
          }
          {
            !loading && starredPromotions.length > 0 &&
            <Row className="mt-1" gap="1" columns={{default: 1, md: 2}}>
              {
                starredPromotions.map((promotion, index) =>
                  (
                    promotion.image?.url && !promotion.href &&
                    <NavLink to="/aktionen" key={index}>
                      <img src={promotion.image.url} alt={promotion.name}/>
                    </NavLink>
                  )
                  || (
                    promotion.image?.url && promotion.href && !isExternalLink(promotion.href) &&
                    <NavLink to={toRelativePath(promotion.href)} key={index}>
                      <img src={promotion.image.url} alt={promotion.name}/>
                    </NavLink>
                  )
                  || (
                    promotion.image?.url && promotion.href && isExternalLink(promotion.href) &&
                    <a href={promotion.href} key={index} target="_blank" rel="noopener noreferrer">
                      <img src={promotion.image.url} alt={promotion.name}/>
                    </a>
                  )
                )
              }
            </Row>
          }
          <BrandSlider className="mt-1-75"/>
        </Section>
      }
      <Section background>
        <h1>Top-Seller</h1>
        {
          loading &&
          <IconAndText className="mt-1" icon={<Loader/>} text="Laden…" align="left"/>
        }
        {
          !loading && topSeller.length === 0 &&
          <IconAndText className="mt-1" icon={<EmptyPackage/>} text="Keine Einträge vorhanden" align="left"/>
        }
        {
          !loading && topSeller.length > 0 &&
          <Row className="mt-1" columns={{default: 1, sm: 2, md: 3}}>
            {topSeller.map((topSeller, index) => <ProductCard key={index} product={topSeller}/>)}
          </Row>
        }
        <p className="mt-2 text-center"><ButtonLink color="primary" to="/produkte" text="Alle Produkte" inline/></p>
      </Section>
      <Section>
        <Row columns={{default: 1, sm: 2, md: 3}}>
          <ImageAndTextCard headline="Downloads" href="/downloads" image="teaser-downloads.webp">
            Broschüren, Packshots oder Markenlogos? All das und noch vieles mehr, finden Sie genau hier.
          </ImageAndTextCard>
          <ImageAndTextCard headline="Aktionen" href="/aktionen" image="teaser-promotions.webp">
            Laufend exklusive online Aktionspakete mit attraktiven Vergütungskonditionen. Die Angebote gibt’s nur
            exklusiv in unserem Shop
          </ImageAndTextCard>
          <ImageAndTextCard headline="Häufige Fragen" href="/support" image="teaser-faq.webp">
            Damit Sie nicht erst den gesamten Shop durchsuchen müssen, finden Sie hier alle Antworten auf häufig
            gestellten Fragen.
          </ImageAndTextCard>
        </Row>
      </Section>
      <ErrorModal errors={errors} onDismiss={() => setErrors([])}/>
    </>
  );
}
