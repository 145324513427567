import './newsletter-subscription.scss';

import Row from "./row";
import {useEffect} from "react";

export default function NewsletterSubscription() {

  useEffect(() => {
    window.hbspt.forms.create({
      region: "na1",
      portalId: "7889907",
      formId: "cf33b825-b385-4142-964e-54436901adbb",
      target: '#hs-subscription-form'
    });
  }, []);

  return (
    <Row className="NewsletterSubscription">
      <div>
        <h3>Newsletter für den Einzelhandel</h3>
        <p>
        Ich möchte gerne auf dem Laufenden bleiben und den Newsletter abonnieren. Mir ist bewusst, dass ich
          mich jederzeit wieder abmelden kann.
        </p>
      </div>
      <div id="hs-subscription-form"></div>
    </Row>
  );
}
