import {useContext, useMemo} from "react";
import {AuthContext} from "../context/auth-context";
import useClaims from "./useClaims";
import config from "../config.json";

export default function useShowCredits() {

  const authContext = useContext(AuthContext);
  const claims = useClaims();

  return useMemo(() => {
    return (
      config.SHOW_CREDITS && authContext.authToken && !claims.sales && !claims.field_sales && !claims.online_sales
      && !claims.credit_excluded
    );
  }, [authContext.authToken, claims.credit_excluded, claims.field_sales, claims.online_sales, claims.sales]);

}
