import Overline from "../component/overline";
import Section from "../component/section";
import OrderItems from "../component/order-items";
import LinkButton from "../component/link-button";
import {useNavigate} from "react-router-dom";

export default function Bestellung() {

  const navigate = useNavigate();

  return (
    <Section background>
      <Overline><LinkButton text="Bestellungen" onClick={() => navigate('/bestellungen')}/> > Bestellung</Overline>
      <OrderItems/>
    </Section>
  );

}
