import './accordion-item.scss';
import {ArrowCircle} from "./icons";
import {useLayoutEffect, useRef} from "react";

export default function AccordionItem({title, children, open = false, setOpen}) {

  const bodyWrapper = useRef();
  useLayoutEffect(() => {
    bodyWrapper.current.style.maxHeight = open ? bodyWrapper.current.children[0].offsetHeight + 'px' : 0;
  }, [open]);

  return (
    <div className="AccordionItem">
      <div className="head" onClick={setOpen}>
        <span>{title}</span><ArrowCircle orientation={open ? 'up' : 'down'}/>
      </div>
      <div ref={bodyWrapper} className="body-wrapper">
        <div className="body">{children}</div>
      </div>
    </div>
  );

}
