import './label-and-text.scss';
import React from "react";

export default function LabelAndText({label, children, className}) {

  return (
    <div className={'LabelAndText' + (className ? ' ' + className : '')}>
      <span>{label}</span>
      <p>{children}</p>
    </div>
  );

}
