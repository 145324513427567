import {Link} from "react-router-dom";
import "./empty-cart.scss";
import DecoratedIcon from "./decorated-icon";
import {ShoppingCart} from "./icons";

export default function EmptyCart() {
  return (
    <div className="EmptyCart">
      <DecoratedIcon icon={<ShoppingCart/>} content="0"/>
      <div>
        <h2>Ihr Warenkorb ist leer</h2>
        <Link to="/produkte">Zu den Produkten</Link>
      </div>
    </div>
  );
}
