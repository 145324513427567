import React from "react";
import './link-button.scss';

export default function LinkButton({text, className, color, onClick}) {
  return (
    <button
      className={'LinkButton' + (color ? ' ' + color : '') + (className ? ' ' + className : '')}
      onClick={onClick} type="button"
    >
      {text}
    </button>
  );
}
