import React, {useContext, useEffect, useState} from "react";
import useStorage from "../hook/useStorage";
import useCRUD from "../hook/useCRUD";
import {AuthContext} from "./auth-context";
import useClaims from "../hook/useClaims";
import ErrorModal from "../component/error-modal";
import useBL from "../hook/useBL";

export const PosContext = React.createContext(
  {pos: [], setPos: pos => {}, activePos: null, setActivePos: pos => {}, loading: false}
);

export function PosContextProvider({children}) {

  const storage = useStorage();
  const crud = useCRUD();
  const bl = useBL();
  const claims = useClaims();
  const authContext = useContext(AuthContext);

  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState([]);
  const [pos, setPos] = useState([]);
  const [activePos, setActivePos] = useState(null);

  useEffect(() => {
    if (authContext.authToken) {
      setLoading(true);
      crud.data.bulk.read({entity: 'point_of_sale'})
        .then(async ({items: pointsOfSale}) => {
          const [creditBalances, expandedPointsOfSale] = await Promise.all([
            bl.get_credit_balance(),
            crud.expand(pointsOfSale, ['invoice_address:address', 'shipping_address:address'])
          ]);
          for (const expandedPointOfSale of expandedPointsOfSale) {
            const creditBalance = creditBalances.find(
              creditBalance => creditBalance.point_of_sale_id === parseInt(expandedPointOfSale.id)
            );
            expandedPointOfSale.balance = creditBalance.balance ?? 0;
          }
          setPos(expandedPointsOfSale);
          const storedActivePosId = storage.local.get('activePos');
          if (!storedActivePosId || !expandedPointsOfSale.map(pos => pos.id).includes(storedActivePosId)) {
            setActivePos(expandedPointsOfSale[0]);
          } else {
            setActivePos(expandedPointsOfSale.find(pos => pos.id === storedActivePosId));
          }
        })
        .catch(errors => setErrors(errors))
        .finally(() => setLoading(false));
    } else {
      setPos([]);
      setActivePos(null);
    }
  }, [authContext.authToken, claims.user_id, crud, storage, bl]);

  useEffect(() => {
    activePos && storage.local.set('activePos', activePos.id);
  }, [activePos, storage.local]);

  return (
    <>
      <PosContext.Provider value={{pos, setPos, activePos, setActivePos, loading}}>{children}</PosContext.Provider>
      <ErrorModal errors={errors} onDismiss={() => setErrors([])}/>
    </>
  );

}
