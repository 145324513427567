import './promotion.scss';
import Row from "./row";
import AmountSelection from "./amount-selection";
import Button from "./button";
import {CircleCheck, ShoppingCartPlus as ShoppingCartPlusIcon} from "./icons";
import {useContext, useState} from "react";
import {ShoppingCartContext} from "../context/shopping-cart-context";
import ButtonLink from "./button-link";

export default function Promotion({promotion, className}) {

  const shoppingCartContext = useContext(ShoppingCartContext);
  const [amount, setAmount] = useState(1);
  const [justAdded, setJustAdded] = useState(false);

  const addToCart = () => {
    shoppingCartContext.addPromotionToCart(promotion.id, amount);
    setJustAdded(true);
    setTimeout(() => setJustAdded(false), 2000);
    setAmount(1);
  };

  return (
    <Row className={'Promotion' + (className ? ' ' + className : '')}>
      <img
        src={promotion?.image?.url && promotion.image.url}
        alt={promotion?.image?.filename && promotion.image.filename}
      />
      <div className="text">
        <div>{promotion.headline}</div>
        {promotion.fact && <ul>{promotion.fact.map((fact, index) => <li key={index}>{fact.fact}</li>)}</ul>}
        {
          (promotion.has_product?.length > 0) &&
          <>
            <AmountSelection amount={amount} onChange={setAmount}/>
            {
              !justAdded
              && <Button text="In den Warenkorb" icon={<ShoppingCartPlusIcon/>} onClick={addToCart}/>
            }
            {
              justAdded
              && <ButtonLink to="/warenkorb" text="Warenkorb anzeigen" icon={<CircleCheck/>} inverted/>
            }
          </>
        }
      </div>
    </Row>
  );

}
