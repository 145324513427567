import Section from "../component/section";
import {useEffect, useState} from "react";
import useCRUD from "../hook/useCRUD";
import Pagination from "../component/pagination";
import {EmptyPackage, Loader} from "../component/icons";
import IconAndText from "../component/icon-and-text";
import ErrorModal from "../component/error-modal";
import ProductCard from "../component/product-card";
import Row from "../component/row";
import FilterSortControl from "../component/filter-sort-control";
import ProductFilter from "../component/product-filter";
import UnevenRow from "../component/uneven-row";
import useClaims from "../hook/useClaims";

export default function Produkte() {

  const pageSize = 12;

  const crud = useCRUD();
  const claims = useClaims();

  const [products, setProducts] = useState([]);
  const [totalProducts, setTotalProducts] = useState();
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState({orderBy: 'name', order: 'asc'});
  const [filter, setFilter] = useState({});
  const [expandFilter, setExpandFilter] = useState(false);
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState([]);

  useEffect(() => {
    setLoading(true);
    let claimsFilter = [];
    if (claims.sales || claims.field_sales) {
      if (claims.sales) {
        claimsFilter.push(crud.filter.equals("is_sales", 1));
      }
      if (claims.field_sales) {
        claimsFilter.push(crud.filter.equals("is_field_sales", 1));
      }
    } else {
      claimsFilter.push(crud.filter.and(
        crud.filter.equals("is_sales", 0),
        crud.filter.equals("is_field_sales", 0)
      ));
    }
    let newFilter;
    if (claimsFilter.length > 0) {
      if (Object.keys(filter).length > 0) {
        newFilter = crud.filter.and(filter, crud.filter.or(...claimsFilter));
      } else {
        newFilter = crud.filter.or(...claimsFilter);
      }
    } else {
      newFilter = filter;
    }
    crud.data.bulk.read(
      {
        entity: 'product', page, page_size: pageSize, order_by: [order.orderBy, 'id'], order: [order.order, 'asc'],
        filter: crud.filter.and(newFilter, crud.filter.equals("free_good", 0))
      }
    )
      .then(async productBulkReadResult => {
        setProducts(await crud.expand(
          productBulkReadResult.items, ['brand', 'product_type', 'image:file']
        ));
        setTotalProducts(productBulkReadResult.total);
      })
      .catch(errors => setErrors(errors))
      .finally(() => setLoading(false));
  }, [crud, page, order, filter, claims]);

  return (
    <>
      <Section background>
        <FilterSortControl order={order} setOrder={setOrder} expandFilter={() => setExpandFilter(true)}/>
        <UnevenRow className="mt-1-75">
          <ProductFilter
            expanded={expandFilter} collapseFilter={() => setExpandFilter(false)}
            setFilter={filter => setPage(0) || setFilter(filter)}
          />
          <div>
            {loading && <IconAndText icon={<Loader/>} text="Laden…" className="mt-1-75"/>}
            {
              !loading && totalProducts === 0 &&
              <IconAndText icon={<EmptyPackage/>} text="Keine Einträge vorhanden." className="mt-2"/>
            }
            {
              !loading && products.length > 0 &&
              <Row columns={{default: 1, sm: 2, lg: 3}}>
                {products.map((product, index) => <ProductCard key={index} product={product}/>)}
              </Row>
            }
            {
              !loading && totalProducts > products.length &&
              <Pagination className="mt-2" total={totalProducts} page={page} pageSize={pageSize} setPage={setPage}/>
            }
          </div>
        </UnevenRow>
      </Section>
      <ErrorModal errors={errors} onDismiss={() => setErrors([])}/>
    </>
  );

}
