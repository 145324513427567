import './download-card.scss';
import Button from "./button";
import {readableDataSize} from "../functions";

export default function DownloadCard({download, className}) {

  return (
    <a
      href={download.file.url} className={'DownloadCard' + (className ? ' ' + className : '')}
      target="_blank" rel="noreferrer"
    >
      <img
        src={download.image?.url ? download.image.url : process.env.PUBLIC_URL + '/placeholder-cigarettes.webp'} alt=""
      />
      <div className="title">{download.name}</div>
      <div className="description">
        {download.file.url.split('.').reverse()[0]}, {readableDataSize(download.file.size)}
      </div>
      <Button text="Download" inline/>
    </a>
  );

}
