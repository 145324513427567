import TextInput from "./text-input";
import {useState} from "react";
import {Eye, EyeOff} from "./icons";

export default function PasswordInput({id, name, label, placeholder, required, className}) {

  const [hidden, setHidden] = useState(true);

  return (
    <TextInput
      type={hidden ? 'password' : 'text'} icon={hidden ? <Eye/> : <EyeOff/>} onIconClick={() => setHidden(x => !x)}
      id={id} name={name} label={label} placeholder={placeholder} required={required} className={className}
    />
  );
}
