import Modal from "./modal";
import {useContext, useState} from "react";
import * as Yup from "yup";
import {Form, Formik} from "formik";
import useBL from "../hook/useBL";
import Button from "./button";
import {Loader} from "./icons";
import TextInput from "./text-input";
import ErrorModal from "./error-modal";
import Row from "./row";
import {PosContext} from "../context/pos-context";

export default function UpdateAbbreviation({pointOfSale, onDismiss}) {

  const bl = useBL();
  const posContext = useContext(PosContext);
  const [errors, setErrors] = useState([]);

  const validationSchema = Yup.object({
    new_abbreviation: Yup.string()
      .required('Bitte eine neue Bezeichnung eingeben')
      .max(3, 'Verwenden Sie maximal 3 Zeichen')
      .test(
        'alreadyExists',
        'Diese Bezeichnung wird bereits von einem anderen Geschäft verwendet',
        value => !posContext.pos.map(x => x.id !== pointOfSale.id && x.abbreviation).includes(value?.toUpperCase())
      )
  });

  const submit = (values, {setSubmitting}) => {
    if (
      values.new_abbreviation.toUpperCase() !== posContext.pos.find(pos => pos.id === pointOfSale.id)?.abbreviation
    ) {
      bl.update_point_of_sale_abbreviation(
        {id: pointOfSale.id, new_abbreviation: values.new_abbreviation.toUpperCase()}
      )
        .then(() => {
          const newPos = [...posContext.pos];
          newPos.find(pos => pos.id === pointOfSale.id).abbreviation = values.new_abbreviation.toUpperCase();
          const newActivePos = {...posContext.activePos};
          if (newActivePos.id === pointOfSale.id) {
            newActivePos.abbreviation = values.new_abbreviation.toUpperCase();
          }
          posContext.setPos(newPos);
          posContext.setActivePos(newActivePos);
          onDismiss();
        })
        .catch(errors => setErrors(errors))
        .finally(() => setSubmitting(false));
    } else {
      setSubmitting(false);
      onDismiss();
    }
  };

  return (
    <Modal title="Bezeichnung ändern" onDismiss={onDismiss}>
      <p>Hier können Sie eine neue Abkürzung mit maximal drei Buchstaben wählen.</p>
      <Formik
        initialValues={{new_abbreviation: pointOfSale.abbreviation}} validationSchema={validationSchema}
        onSubmit={submit} validate={() => setErrors([])}
      >
        {({isSubmitting}) => (
          <Form>
            <TextInput name="new_abbreviation"/>
            <Row className="mt-1" gap="1" justify="end">
              <Button text="Abbrechen" onClick={onDismiss} inverted inline/>
              <Button
                text="Speichern" type="submit" icon={isSubmitting ? <Loader/> : null} disabled={isSubmitting}
                inline iconPosition="right"
              />
            </Row>
          </Form>
        )}
      </Formik>
      <ErrorModal errors={errors} onDismiss={() => setErrors([])}/>
    </Modal>
  );

}
