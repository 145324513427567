export {displayPrice, displayWholesaler, isExternalLink, readableDataSize, toRelativePath};

/**
 * Formats a given price in cents to a human-readable string in EUR.
 *
 * This function takes an input price in cents and converts it to a string
 * representation in euros using the German locale formatting. The output
 * string includes the euro currency symbol and ensures that the price is
 * displayed with two decimal places.
 *
 * The function uses the `Intl.NumberFormat` object to handle the formatting
 * according to the 'de-DE' locale, which specifies the use of comma as the
 * decimal separator and period as the thousands' separator.
 *
 * @param {number} price - The price in cents to be converted.
 * @returns {string} - The formatted price in euros with appropriate currency symbol and decimal places.
 *
 * @example
 * console.log(displayPrice(123456)); // Output: "1.234,56 €"
 * console.log(displayPrice(5000));   // Output: "50,00 €"
 */
function displayPrice(price) {
  return Intl.NumberFormat(
    'de-DE', {style: 'currency', currency: 'EUR', minimumFractionDigits: 2, maximumFractionDigits: 2}
  ).format(price / 100);
}

/**
 * Determines and returns the name of the wholesaler associated with an order.
 *
 * This function examines the properties of an order object to determine the appropriate
 * wholesaler name to display. The decision logic is as follows:
 * - If the order has a `custom_wholesaler` property, it returns the value of this property.
 * - If the order does not have a `custom_wholesaler` but has a `wholesaler_id` property,
 *   it checks if the `wholesaler` property exists:
 *   - If the `wholesaler` property exists, it returns the name of the wholesaler.
 *   - If the `wholesaler` property does not exist, it returns 'Gelöscht'.
 * - If the order has neither a `custom_wholesaler` nor a `wholesaler_id` property,
 *   it returns 'Direktabrechnung'.
 *
 * @param {Object} order - The order object containing wholesaler information.
 * @param {string} [order.custom_wholesaler] - The custom wholesaler name, if any.
 * @param {number} [order.wholesaler_id] - The ID of the wholesaler, if any.
 * @param {Object} [order.wholesaler] - The wholesaler object, if available.
 * @param {string} [order.wholesaler.name] - The name of the wholesaler.
 * @returns {string} - The name of the wholesaler or an appropriate placeholder text.
 *
 * @example
 * const order1 = { custom_wholesaler: 'Custom Wholesaler Ltd' };
 * console.log(displayWholesaler(order1)); // Output: "Custom Wholesaler Ltd"
 *
 * const order2 = { wholesaler_id: 123, wholesaler: { name: 'Wholesaler Inc' } };
 * console.log(displayWholesaler(order2)); // Output: "Wholesaler Inc"
 *
 * const order3 = { wholesaler_id: 123 };
 * console.log(displayWholesaler(order3)); // Output: "Gelöscht"
 *
 * const order4 = {};
 * console.log(displayWholesaler(order4)); // Output: "Direktabrechnung"
 */
function displayWholesaler(order) {
  let result = '';
  if (order.custom_wholesaler) {
    result = order.custom_wholesaler;
  } else {
    if (order.wholesaler_id) {
      result = order.wholesaler ? order.wholesaler.name : 'Gelöscht';
    } else {
      result = 'Direktabrechnung';
    }
  }
  return result;
}

/**
 * Checks if a given URL is external.
 *
 * This function determines whether the provided URL is external based on
 * its hostname compared to the current window's hostname. It also handles
 * relative URLs, treating them as internal.
 *
 * @param {string} url - The URL to be checked.
 * @returns {boolean} - Returns true if the URL is external, false if it is internal.
 */
function isExternalLink(url) {
  try {
    const link = new URL(url, window.location.href);
    return link.hostname !== window.location.hostname;
  } catch (e) {
    // If the URL cannot be parsed, we treat it as an internal (relative) URL
    return false;
  }
}

/**
 * Converts a given data size in bytes to a more human-readable format.
 *
 * This function takes an input size in bytes and converts it to a string
 * representation using the appropriate unit (Bytes, KB, MB, or GB). The
 * conversion follows these rules:
 * - If the size is less than 1024 bytes, it returns the size in bytes.
 * - If the size is less than 1,048,576 bytes (1024^2), it returns the size in kilobytes (KB).
 * - If the size is less than 1,073,741,824 bytes (1024^3), it returns the size in megabytes (MB).
 * - Otherwise, it returns the size in gigabytes (GB).
 *
 * @param {number} size - The size in bytes to be converted.
 * @returns {string} - The converted size in a human-readable format with appropriate units.
 *
 * @example
 * console.log(readableDataSize(500));        // Output: "500 Byte"
 * console.log(readableDataSize(1500));       // Output: "1 KB"
 * console.log(readableDataSize(1048576));    // Output: "1 MB"
 * console.log(readableDataSize(1073741824)); // Output: "1 GB"
 */
function readableDataSize(size) {
  if (size < 1024) {
    return (size + ' Byte');
  }
  if (size < Math.pow(1024, 2)) {
    return (Math.round(size / 1024) + ' KB');
  }
  if (size < Math.pow(1024, 3)) {
    return (Math.round(size / Math.pow(1024, 2)) + ' MB');
  }
  return (Math.round(size / Math.pow(1024, 3)) + ' GB');
}

/**
 * Converts an absolute internal URL to a relative path.
 *
 * This function removes the protocol and hostname from the URL, returning only the path and search/query part.
 *
 * @param {string} url - The absolute URL to be converted.
 * @returns {string} - The relative path.
 */
function toRelativePath(url) {
  try {
    const link = new URL(url, window.location.href);
    return link.pathname + link.search + link.hash;
  } catch (e) {
    // If URL parsing fails, return the original URL
    return url;
  }
}
