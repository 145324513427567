import {useField, useFormikContext} from "formik";
import './text-input.scss';
import Error from "./error";

export default function TextAreaInput({id, name, label, placeholder, button, required, className}) {

  const [field, meta] = useField(name);
  const {isSubmitting} = useFormikContext();

  return (
    <div
      className={
        'TextInput' + (meta.touched && meta.error ? ' has-error' : '') + (required ? ' required' : '') +
        (className ? ' ' + className : '')
      }
    >
      {label && <label htmlFor={id || name}>{label}</label>}
      <div className="wrapper">
        <textarea id={id || name} placeholder={placeholder} disabled={isSubmitting} {...field}/>
        {button}
      </div>
      <Error className="mt-025">{meta.touched && meta.error}</Error>
    </div>
  );

}
