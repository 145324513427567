import "./credit-transactions.scss";
import {useContext, useEffect, useState} from "react";
import useCRUD from "../hook/useCRUD";
import IconAndText from "../component/icon-and-text";
import {EmptyPackage, Loader} from "./icons";
import ErrorModal from "../component/error-modal";
import Pagination from "./pagination";
import {PosContext} from "../context/pos-context";

export default function CreditTransactions ({future = false}) {

  const crud = useCRUD();
  const posContext = useContext(PosContext);

  const pageSize = 10;
  const [page, setPage] = useState(0);
  const order = 'asc';
  const orderBy = 'created_at';

  const [totalCreditTransactions, setTotalCreditTransactions] = useState(0);
  const [creditTransactions, setCreditTransactions] = useState([]);

  const [showLoading, setShowLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState([]);

  useEffect(() => {
    setLoading(true);
    setShowLoading(false);
    const timeout = setTimeout(() => setShowLoading(true), 1000);
    const now = new Date().toISOString();

    let validityFilter = crud.filter.lessOrEqual('validity', now);
    if (future) {
      validityFilter = crud.filter.greater('validity', now);
    }
    
    crud.data.bulk.read({
      entity: 'credit_transaction',
      filter: crud.filter.and(validityFilter, crud.filter.equals('point_of_sale_id', posContext.activePos.id)),
      page_size: pageSize, page: page, order, order_by: orderBy
    })
      .then(creditTransactionResult => {
        setTotalCreditTransactions(creditTransactionResult.total);
        setCreditTransactions(creditTransactionResult.items);
      })
      .finally(() => clearTimeout(timeout) || setShowLoading(false) || setLoading(false))
      .catch(error => setErrors(error));
  }, [crud, future, page, posContext.activePos]);

  return (
    <div className="CreditTransactions">
      {
        loading && showLoading &&
        <IconAndText icon={<Loader/>} text="Laden…" align="left"/>
      }

      {
        !loading && creditTransactions?.length === 0 &&
        <IconAndText className="mt-1" icon={<EmptyPackage/>} text="Keine Buchungen gefunden" align="center"/>
      }
      {
        !loading && !showLoading && creditTransactions?.length > 0 &&
        <div className="table">
          <div className="header">
            <div>Datum</div>
            <div>Punkte</div>
            <div>{future ? "Verwendbar ab" : "Verwendbar seit"}</div>
            <div>Grund</div>
            <div>Referenz</div>
          </div>
          <div className="body">
            {
              creditTransactions.map((transaction, index) => {
                let sourceText;
                switch (transaction.source) {
                case "order":
                  sourceText = "Gutschrift durch Bestellung";
                  break;
                case "cadooz\\cancel":
                  sourceText = "Gutschrift durch Cadooz";
                  break;
                case "cadooz\\debit":
                  sourceText = "Abbuchung durch Cadooz";
                  break;
                default:
                  sourceText = transaction.source;
                }
                return (
                  <div key={index} className="table-row">
                    <div className="property">
                      <div className="key">Datum:</div>
                      <div className="value">
                        {
                          new Date(transaction.created_at).toLocaleString(
                            'de-DE',
                            {day: '2-digit', month: '2-digit', year: 'numeric', hour: '2-digit', minute: '2-digit'}
                          )
                        }
                        &nbsp;Uhr
                      </div>
                    </div>
                    <div className="property">
                      <div className="key">Punkte:</div>
                      <div className="value">{parseInt(transaction.amount).toLocaleString()}</div>
                    </div>
                    <div className="property">
                      <div className="key">Verwendbar ab:</div>
                      <div className="value">
                        {
                          new Date(transaction.validity).toLocaleString(
                            'de-DE',
                            {day: '2-digit', month: '2-digit', year: 'numeric', hour: '2-digit', minute: '2-digit'}
                          )
                        }
                        &nbsp;Uhr
                      </div>
                    </div>
                    <div className="property">
                      <div className="key">Grund:</div>
                      <div className="value">{sourceText}</div>
                    </div>
                    <div className="property">
                      <div className="key">Referenz:</div>
                      <div className="value">{transaction.reference}</div>
                    </div>
                  </div>
                );
              })
            }
          </div>
        </div>
      }
      {
        !loading && totalCreditTransactions > creditTransactions.length > 0 &&
        <Pagination className="mt-1" total={totalCreditTransactions} page={page} setPage={setPage} pageSize={pageSize}/>
      }
      <ErrorModal errors={errors} onDismiss={() => setErrors([])}/>
    </div>
  );

}
