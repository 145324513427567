import Section from "../component/section";
import ButtonLink from "../component/button-link";

export default function NotFound() {
  return (
    <Section className="text-center">
      <h1 className="jumbo">404</h1>
      <p><strong>Ups, da ist etwas schiefgelaufen.</strong></p>
      <p>
        Die angeforderte Seite konnte nicht gefunden werden, aber keine Sorge, wir bringen Sie wieder auf den
        richtigen Weg.
      </p>
      <p><ButtonLink to="/" text="Klicken Sie hier, um zur Startseite zu gelangen" inline/></p>
    </Section>
  );
}
