import {Navigate} from "react-router-dom";
import useClaims from "../hook/useClaims";

export default function DenySales({children}) {
  const claims = useClaims();
  let salesNavigationTarget = null;
  if (claims.sales || claims.field_sales) {
    salesNavigationTarget = "/produkte";
  }
  if (claims.online_sales) {
    salesNavigationTarget = "/downloads";
  }
  return salesNavigationTarget ? <Navigate to={salesNavigationTarget} replace/> : children;
}
