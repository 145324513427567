import React, {useCallback, useContext, useEffect, useState} from "react";
import useStorage from "../hook/useStorage";
import useBL from "../hook/useBL";
import ErrorModal from "../component/error-modal";
import {AuthContext} from "./auth-context";
import {PosContext} from "./pos-context";

export const ShoppingCartContext = React.createContext({
  shoppingCart: {}, addToCart: (entity, entity_id, amount) => {}, setAmount: (entity, entity_id, amount) => {},
  newShoppingCart: () => {}, removeFromCart: (entity, entity_id) => {} ,
  addPromotionToCart: (promotion_id, amount) => {},
});

export function ShoppingCartContextProvider({children}) {

  const bl = useBL();
  const authContext = useContext(AuthContext);
  const posContext = useContext(PosContext);
  const storage = useStorage();

  const [shoppingCart, setShoppingCart] = useState({line_items: []});
  const [errors, setErrors] = useState([]);
  const [reloadKey, setReloadKey] = useState(0);

  const newShoppingCart = useCallback(() => {
    bl.new_shopping_cart()
      .then(shoppingCart => setShoppingCart(shoppingCart))
      .catch(errors => setErrors(errors));
  }, [bl]);

  const addToCart = (entity, entity_id, amount) => {
    bl.add_to_shopping_cart({
      shopping_cart_id: shoppingCart.id, entity, entity_id, amount, point_of_sale_id: posContext.activePos?.id
    })
      .then(shoppingCart => setShoppingCart(shoppingCart))
      .catch(errors => setReloadKey(x => x + 1) || setErrors(errors));
  };

  const setAmount = (entity, entity_id, amount) => {
    bl.set_shopping_cart_amount({
      shopping_cart_id: shoppingCart.id, entity, entity_id, amount, point_of_sale_id: posContext.activePos?.id
    })
      .then(shoppingCart => setShoppingCart(shoppingCart))
      .catch(errors => setErrors(errors));
  };

  const removeFromCart = (entity, entity_id) => {
    bl.remove_from_shopping_cart({
      shopping_cart_id: shoppingCart.id, entity, entity_id, point_of_sale_id: posContext.activePos?.id
    })
      .then(shoppingCart => setShoppingCart(shoppingCart))
      .catch(errors => setErrors(errors));
  };

  const addPromotionToCart = (promotion_id, amount) => {
    bl.add_promotion_to_shopping_cart({
      shopping_cart_id: shoppingCart.id, promotion_id, amount, point_of_sale_id: posContext.activePos?.id
    })
      .then(shoppingCart => setShoppingCart(shoppingCart))
      .catch(errors => setReloadKey(x => x + 1) || setErrors(errors));
  };

  useEffect(() => {
    if (authContext.authToken) {
      const storedShoppingCart = storage.local.get('shoppingCart');
      if (storedShoppingCart && storedShoppingCart.id) {
        if (posContext.activePos) {
          bl.get_shopping_cart({shopping_cart_id: storedShoppingCart.id, point_of_sale_id: posContext.activePos?.id})
            .then(shoppingCart => setShoppingCart(shoppingCart))
            .catch(newShoppingCart);
        }
      } else {
        newShoppingCart();
      }
    }
  }, [authContext.authToken, bl, newShoppingCart, storage, reloadKey, posContext.activePos]);

  useEffect(() => {
    shoppingCart.id && storage.local.set('shoppingCart', shoppingCart);
  }, [storage, shoppingCart]);

  return (
    <ShoppingCartContext.Provider value={{
      shoppingCart, addToCart, setAmount, newShoppingCart, removeFromCart, addPromotionToCart
    }}>
      <>
        {children}
        <ErrorModal errors={errors} onDismiss={() => setErrors([])}/>
      </>
    </ShoppingCartContext.Provider>
  );

}
