import Section from "../component/section";
import Button from "../component/button";
import {useContext, useEffect, useState} from "react";
import {AuthContext} from "../context/auth-context";
import {useNavigate} from "react-router-dom";
import useCRUD from "../hook/useCRUD";
import ErrorModal from "../component/error-modal";
import useClaims from "../hook/useClaims";
import IconAndText from "../component/icon-and-text";
import {Check, Loader} from "../component/icons";
import Row from "../component/row";
import Widget from "../component/widget";
import LabelAndText from "../component/label-and-text";
import useStorage from "../hook/useStorage";
import UpdateAbbreviation from "../component/update-abbreviation";
import ChangePassword from "../component/change-password";
import Abbreviation from "../component/abbreviation";
import {PosContext} from "../context/pos-context";
import GreetingText from "../component/greeting-text";
import DottedCard from "../component/dotted-card";
import Overline from "../component/overline";
import ContactForm from "../component/contact-form";
import config from "../config.json";
import PointsBanner from "../component/points-banner";
import RedeemPointsButton from "../component/redeem_points_button";
import AbbreviationAndBalance from "../component/abbreviation_and_balance";
import useShowCredits from "../hook/useShowCredits";
import ButtonLink from "../component/button-link";

export default function Konto() {

  const authContext = useContext(AuthContext);
  const posContext = useContext(PosContext);
  const navigate = useNavigate();
  const crud = useCRUD();
  const claims = useClaims();
  const showCredits = useShowCredits();

  const [user, setUser] = useState(null);
  const [errors, setErrors] = useState([]);
  const [loading, setLoading] = useState(false);
  const [updateAbbreviation, setUpdateAbbreviation] = useState(null);
  const [changePassword, setChangePassword] = useState(false);
  const [showContactForm, setShowContactForm] = useState(false);

  const storage = useStorage();
  const logout = () => {
    authContext.setAuthToken('');
    storage.session.remove('authToken');
    storage.local.remove('authToken');
    navigate('/');
  };

  useEffect(() => {
    setLoading(true);
    crud.data.read({entity: 'user', id: claims.user_id})
      .then(user => setUser(user))
      .catch(errors => setErrors(errors))
      .finally(() => setLoading(false));
  }, [claims.user_id, crud]);

  return (
    <>
      {changePassword && <ChangePassword onDismiss={() => setChangePassword(false)}/>}
      {
        updateAbbreviation &&
        <UpdateAbbreviation pointOfSale={updateAbbreviation} onDismiss={() => setUpdateAbbreviation(null)}/>
      }
      <Section background>
        <Overline>Konto</Overline>
        {loading && <IconAndText icon={<Loader/>} text="Laden…" align="left"/>}
        {
          !loading && user &&
          <GreetingText>
            <h2>{('Hallo ' + (user.first_name ?? '') + ' ' + (user.last_name ?? '')).trim()},</h2>
            <p>schön, dass Sie hier sind.</p>
          </GreetingText>
        }
        {
          showCredits && config.CADOOZ_URL &&
          <>
            <PointsBanner/>
            <ButtonLink className="mt-1" inline to="/bonusclub" text="Mehr über den Landewyck Bonusclub erfahren"/>
          </>
        }
        <h3 className="mt-2">Mein Profil</h3>
        {loading && <IconAndText icon={<Loader/>} text="Laden…" align="left"/>}
        {
          !loading && user &&
          <Row className="mt-1" columns={{default: 1, sm: 2, lg: 3}}>
            <DottedCard>
              <Widget>
                <LabelAndText label="Name">{user.first_name} {user.last_name}</LabelAndText>
                <LabelAndText label="E-Mail">{user.email}</LabelAndText>
                <LabelAndText label="Password">************</LabelAndText>
              </Widget>
              <div>
                <Button text="Passwort ändern" color="primary" onClick={() => setChangePassword(true)}/>
                <Button text="Änderungen mitteilen" inverted onClick={() => setShowContactForm(true)}/>
              </div>
            </DottedCard>
          </Row>
        }
        <h3 className="mt-2">{posContext.pos.length === 1 ? 'Mein Geschäft' : 'Meine Geschäfte'}</h3>
        {loading && <IconAndText icon={<Loader/>} text="Laden…" align="left"/>}
        {
          !loading && posContext.pos.length > 0 &&
          <Row className="mt-1" columns={{default: 1, sm: 2, lg: 3}}>
            {posContext.pos.map(
              (pos, index) => (
                <DottedCard key={index}>
                  <Widget>
                    {
                      !showCredits && posContext.pos.length > 1 &&
                      <Abbreviation text={pos.abbreviation}/>
                    }
                    {
                      showCredits && posContext.pos.length > 1 &&
                      <AbbreviationAndBalance point_of_sales={pos}/>
                    }
                    <LabelAndText label="Lieferadresse" className={posContext.pos.length > 1 ? 'mt-1' : null}>
                      {pos.shipping_address.name && <>{pos.shipping_address.name}<br/></>}
                      {pos.shipping_address.street}<br/>
                      {pos.shipping_address.postal_code} {pos.shipping_address.city}
                    </LabelAndText>
                    <LabelAndText label="Rechnungsadresse" className="mt-1">
                      {pos.invoice_address.name && <>{pos.invoice_address.name}<br/></>}
                      {pos.invoice_address.street}<br/>
                      {pos.invoice_address.postal_code} {pos.invoice_address.city}
                    </LabelAndText>
                  </Widget>
                  {
                    showCredits && config.CADOOZ_URL && posContext.pos.length > 1 &&
                    <RedeemPointsButton inline={false} point_of_sales={pos}/>
                  }
                  {
                    posContext.pos.length > 1 &&
                    <div>
                      <Button text="Bezeichnung ändern" inverted onClick={() => setUpdateAbbreviation(pos)}/>
                      {
                        !posContext.loading && posContext.activePos.id !== pos.id &&
                        <Button text="Auswählen" onClick={() => posContext.setActivePos(pos)}/>
                      }
                      {
                        !posContext.loading && posContext.activePos.id === pos.id &&
                        <IconAndText icon={<Check/>} text="Aktuell ausgewählt" style={{height: '38px'}}/>
                      }
                    </div>
                  }
                </DottedCard>
              )
            )}
          </Row>
        }
        <hr className="mt-2"/>
        <Button
          className="mt-2" text="Andere Änderungen mitteilen" inverted inline onClick={() => setShowContactForm(true)}
        />
      </Section>
      <Section>
        <Row columns={{default: 1, lg: 2}}>
          <div>
            <h3>Abmelden</h3>
            <p className="color-secondary">
              Standardmäßig bleiben Sie für 90 Tage im System angemeldet.
              Wenn Sie sich aktiv abmelden wollen, können Sie dies hier tun.
            </p>
            <Button text="Abmelden" onClick={logout} inline/>
          </div>
        </Row>
      </Section>
      {showContactForm && <ContactForm onDismiss={() => setShowContactForm(false)}/>}
      <ErrorModal errors={errors} onDismiss={() => setErrors([])}/>
    </>
  );

}
