import Section from "../component/section";
import Download from "../component/download";
import Row from "../component/row";
import DownloadCard from "../component/download-card";
import useCRUD from "../hook/useCRUD";
import {useEffect, useState} from "react";
import IconAndText from "../component/icon-and-text";
import {EmptyPackage, Loader} from "../component/icons";
import ErrorModal from "../component/error-modal";
import Overline from "../component/overline";
import useClaims from "../hook/useClaims";

export default function Downloads() {

  const crud = useCRUD();
  const claims = useClaims();

  const [downloads, setDownloads] = useState([]);
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState([]);

  useEffect(() => {
    setLoading(true);
    let claimsFilter = [];
    if (claims.sales || claims.field_sales || claims.online_sales) {
      if (claims.sales) {
        claimsFilter.push(crud.filter.equals("is_sales", 1));
      }
      if (claims.field_sales) {
        claimsFilter.push(crud.filter.equals("is_field_sales", 1));
      }
      if (claims.online_sales) {
        claimsFilter.push(crud.filter.equals("is_online_sales", 1));
      }
    } else {
      claimsFilter.push(crud.filter.and(
        crud.filter.equals("is_sales", 0),
        crud.filter.and(
          crud.filter.equals("is_field_sales", 0),
          crud.filter.equals("is_online_sales", 0)
        )
      ));
    }
    crud.data.bulk.read({entity: 'download', page_size: 999, filter: crud.filter.or(...claimsFilter)})
      .then(async result => {
        setDownloads(await crud.expand(result.items, ['file', 'image:file']));
      })
      .catch(errors => setErrors(errors))
      .finally(() => setLoading(false));
  }, [claims, crud]);

  const loader = <IconAndText className="mt-2" icon={<Loader/>} text="Laden…" align="left"/>;
  const empty = <IconAndText className="mt-2" icon={<EmptyPackage/>} text="Keine Einträge vorhanden." align="left"/>;

  return (
    <>
      <Section background>
        <Overline>Downloads</Overline>
        <h1 className="narrow">Alles, was Sie brauchen. Mit nur einem Klick.</h1>
        <h2 className="narrow">Broschüren und Informations&shy;material</h2>
        {loading && loader}
        {!loading && downloads.filter(download => download.download_category_id === '1').length === 0 && empty}
        {!loading && downloads.length > 0 &&
          downloads
            .filter(download => download.download_category_id === '1')
            .map(
              (download, index) => <Download key={index} className={'mt-' + (index === 0 ? 2 : 1)} download={download}/>
            )
        }
      </Section>
      <Section>
        <h2>Packshots</h2>
        {loading && loader}
        {!loading && downloads.filter(download => download.download_category_id === '2').length === 0 && empty}
        {!loading && downloads.length > 0 &&
          <Row className="mt-2" columns={{default: 1, sm: 2, lg: 3}}>
            {
              downloads
                .filter(download => download.download_category_id === '2')
                .map((download, index) => <DownloadCard key={index} download={download}/>)
            }
          </Row>
        }
      </Section>
      <Section>
        <h2>Markenlogos</h2>
        {loading && loader}
        {!loading && downloads.filter(download => download.download_category_id === '3').length === 0 && empty}
        {!loading && downloads.length > 0 &&
          <Row className="mt-2" columns={{default: 1, sm: 2, lg: 3}}>
            {
              downloads
                .filter(download => download.download_category_id === '3')
                .map((download, index) => <DownloadCard key={index} download={download}/>)
            }
          </Row>
        }
      </Section>
      <ErrorModal errors={errors} onDismiss={() => setErrors([])}/>
    </>
  );
}
