import './points-banner.scss';
import Balance from "./balance";
import Overline from "./overline";
import Abbreviation from "./abbreviation";
import {useContext} from "react";
import {PosContext} from "../context/pos-context";
import RedeemPointsButton from "./redeem_points_button";
import ButtonLink from "./button-link";

export default function PointsBanner({className}) {

  const posContext = useContext(PosContext);

  return (
    !posContext.loading && posContext.activePos &&
    <div className={'PointsBanner' + (className ? ' ' + className : '')}>
      <div className="text">
        <Overline>Landewyck-Points</Overline>
        <h3>Jetzt Punkte einlösen und profitieren.</h3>
        <div className="buttons">
          <ButtonLink inverted inline text="Konto ansehen" to="/punktekonto"/>
          <RedeemPointsButton inline point_of_sales={posContext.activePos}/>
        </div>
      </div>
      {
        posContext.pos.length > 1 && <Abbreviation text={posContext.activePos.abbreviation}/>
      }
      <div className="balance"><Balance balance={posContext.activePos.balance} color="white" size="big"/></div>
    </div>
  );

}
