import {Form, Formik} from "formik";
import * as Yup from "yup";
import TextInput from "./text-input";
import Button from "./button";
import Modal from "./modal";
import {Loader} from "./icons";
import useBL from "../hook/useBL";
import {useState} from "react";
import ErrorModal from "./error-modal";
import Row from "./row";

export default function RequestPasswordRecovery({onDismiss}) {

  const bl = useBL();

  const [errors, setErrors] = useState([]);
  const [showSuccess, setShowSuccess] = useState(false);

  const validate = Yup.object({
    username_or_email: Yup.string()
      .required('Bitte füllen Sie dieses Pflichtfeld aus')
  });

  const submit = (values, {setSubmitting}) => {
    bl.recover_password({username_or_email: values.username_or_email,
      url: window.location.origin + '/passwort-zuruecksetzen/TOKEN'})
      .then(() => setShowSuccess(true))
      .catch(errors => setErrors(errors))
      .finally(() => setSubmitting(false));
  };

  return (
    <Modal title="Passwort neu vergeben" className="RequestPasswordRecovery" onDismiss={onDismiss}>
      {
        !showSuccess &&
        <>
          <p>
            Bitte geben Sie Ihre Kundennummer oder Ihre E-Mail-Adresse ein. Sie erhalten einen Link per E-Mail, womit
            Sie sich ein neues Passwort erstellen können.
          </p>
          <Formik
            initialValues={{username_or_email: ''}} validationSchema={validate} onSubmit={submit}
            validate={() => setErrors([])}
          >
            {({isSubmitting}) => (
              <Form>
                <TextInput name="username_or_email" label="E-Mail-Adresse" required/>
                <Row className="mt-1" justify="end" gap="1">
                  <Button text="Abbrechen" onClick={onDismiss} inverted inline/>
                  <Button
                    text="Passwort zurücksetzen" type="submit" icon={isSubmitting && <Loader/>} disabled={isSubmitting}
                    inline iconPosition="right"
                  />
                </Row>
              </Form>
            )}
          </Formik>
          <ErrorModal errors={errors} onDismiss={() => setErrors([])}/>
        </>
      }
      {
        showSuccess &&
        <>
          <p>
            Eine E-Mail zum Zurücksetzen des Passworts wurde an Ihre E-Mail-Adresse gesendet. Es kann jedoch dauern,
            bis die E-Mail in Ihrem Postfach erscheint. Bitte warten Sie mindestens 10 Minuten, bis Sie es noch einmal
            versuchen.
          </p>
          <p className={"text-right"}>
            <Button text="Schliessen" onClick={onDismiss} inline/>
          </p>
        </>
      }
    </Modal>
  );

}
