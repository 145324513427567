import Section from "../component/section";
import useCRUD from "../hook/useCRUD";
import {useEffect, useState} from "react";
import ErrorModal from "../component/error-modal";
import IconAndText from "../component/icon-and-text";
import {EmptyPackage, Loader} from "../component/icons";
import Promotion from "../component/promotion";
import Overline from "../component/overline";

export default function Aktionen() {

  const crud = useCRUD();
  const pageSize = 9999;
  const [promotions, setPromotions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState([]);
  const [totalPromotions, setTotalPromotions] = useState(0);

  useEffect(() => {
    setLoading(true);
    crud.data.bulk.read({entity: 'promotion', page: 0, page_size: pageSize})
      .then(async ({items: promotions, total: totalPromotions}) => {
        let expandedPromotions = await crud.expand(
          promotions,
          ['image:file', 'fact<promotion_fact', 'has_product<promotion_has_product']
        );
        setPromotions(expandedPromotions.filter(promotion =>
          (promotion.price !== null && promotion.price !== '0') || promotion.has_product?.length > 0
        ));
        setTotalPromotions(totalPromotions);
      })
      .catch(errors => setErrors(errors))
      .finally(() => setLoading(false));
  }, [crud]);

  return (
    <>
      <Section background>
        <Overline>Aktionen</Overline>
        <h1 className="narrow">Alles für Ihr Geschäft</h1>
      </Section>
      {loading && <Section><IconAndText icon={<Loader/>} text="Laden…" align="left"/></Section>}
      {
        !loading && totalPromotions === 0 &&
        <Section><IconAndText icon={<EmptyPackage/>} text="Keine Einträge vorhanden." align="left"/></Section>
      }
      {
        !loading && promotions.length > 0 &&
        promotions.map((promotion, index) => <Section key={index}><Promotion promotion={promotion}/></Section>)
      }
      <ErrorModal errors={errors} onDismiss={() => setErrors([])}/>
    </>
  );

}
