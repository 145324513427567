import './dropdown-item.scss';

export default function DropdownItem({onSelect, children}) {
  return (
    <div className="DropdownItem" onClick={onSelect}>
      {typeof children === 'string' && <span className="text">{children}</span>}
      {typeof children !== 'string' && children}
    </div>
  );
}
