import './widget.scss';
import ButtonLink from "./button-link";
import Button from "./button";

export default function Widget({headline, children, buttonText, buttonTo = '', buttonInverted, className, onClick}) {

  return (
    <div className={'Widget' + (className ? ' ' + className : '')}>
      {headline && <h3>{headline}</h3>}
      {typeof children === 'string' && <p>{children}</p>}
      {typeof children !== 'string' && <div>{children}</div>}
      {buttonText && buttonTo && <ButtonLink text={buttonText} to={buttonTo} inverted={buttonInverted}/>}
      {buttonText && onClick && <Button text={buttonText} onClick={onClick} inverted={buttonInverted}/>}
    </div>
  );
}
