import './brand-slider.scss';
import {useEffect, useRef, useState} from "react";
import useCRUD from "../hook/useCRUD";
import ErrorModal from "./error-modal";
import IconAndText from "./icon-and-text";
import {Loader} from "./icons";

export default function BrandSlider({className}) {
  
  const slider = useRef();
  const crud = useCRUD();

  const [transition, setTransition] = useState(false);
  const [brands, setBrands] = useState([]);
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState([]);
  
  useEffect(() => {
    setLoading(true);
    crud.data.bulk.read({entity: 'brand'})
      .then(async bulkReadResult => setBrands(await crud.expand(bulkReadResult.items, ['image:file'])))
      .catch(errors => setErrors(errors))
      .finally(() => setLoading(false));
  }, [crud]);

  const mouseDownHandler = function(e) {
    e.preventDefault();
    let
      sliderStart = 0,
      change,
      sliderWidth = slider.current.parentNode.getBoundingClientRect().width,
      sliderItemWidth = slider.current.firstChild.getBoundingClientRect().width +
        parseInt(window.getComputedStyle(slider.current.firstChild, null).marginRight.slice(0,1)),
      sliderInnerMaxScroll = ((slider.current.children.length * sliderItemWidth - sliderWidth) * -1),
      clickPositionStart = e.pageX,
      slidermove = true
    ;
    setTransition(false);
    sliderStart = clickPositionStart - slider.current.offsetLeft;
    document.addEventListener('mousemove', function mousemove(e) {
      change = e.pageX - sliderStart;
      if (slidermove) {
        if (change > 0) {
          slider.current.style.marginLeft = '0px';
          change = 0;
        } else if (change < sliderInnerMaxScroll) {
          change = sliderInnerMaxScroll;
          slider.current.style.marginLeft = sliderInnerMaxScroll + 'px';
        } else {
          slider.current.style.marginLeft = change + 'px';
        }
      } else {
        document.removeEventListener('mousemove', mousemove);
      }
    });
    document.addEventListener('mouseup', function mouseUpHandler (e) {
      setTransition(true);
      slidermove = false;
      if (change === sliderInnerMaxScroll) {
        slider.current.style.marginLeft = sliderInnerMaxScroll + 'px';
      } else {
        slider.current.style.marginLeft = (Math.round((change * -1) / sliderItemWidth) * sliderItemWidth) * -1 + 'px';
      }
      if (clickPositionStart === e.pageX) {
        window.location = e.target.href;
      } else {
        e.preventDefault();
      }
      document.removeEventListener('mouseup', mouseUpHandler);
    });
  };

  const touchStartHandler = function(e) {
    let
      sliderStart = 0,
      change,
      sliderWidth = slider.current.parentNode.getBoundingClientRect().width,
      sliderItemWidth = slider.current.firstChild.getBoundingClientRect().width +
        parseInt(window.getComputedStyle(slider.current.firstChild, null).marginRight.slice(0, 1)),
      sliderInnerMaxScroll = ((slider.current.children.length * sliderItemWidth - sliderWidth) * -1),
      clickPositionStart = e.changedTouches[0].pageX,
      slidermove = true
    ;
    setTransition(false);
    sliderStart = clickPositionStart - slider.current.offsetLeft;
    document.addEventListener('touchmove', function touchMove(e) {
      change = e.changedTouches[0].pageX - sliderStart;
      if (slidermove) {
        if (change > 0) {
          slider.current.style.marginLeft = '0px';
          change = 0;
        } else if (change < sliderInnerMaxScroll) {
          change = sliderInnerMaxScroll;
          slider.current.style.marginLeft = sliderInnerMaxScroll + 'px';
        } else {
          slider.current.style.marginLeft = change + 'px';
        }
      } else {
        document.removeEventListener('mousemove', touchMove, false);
      }
    });
    document.addEventListener('touchend', function touchEndHandler (e) {
      setTransition(true);
      slidermove = false;
      if (change === sliderInnerMaxScroll) {
        slider.current.style.marginLeft = sliderInnerMaxScroll + 'px';
      } else {
        slider.current.style.marginLeft = (Math.round((change * -1) / sliderItemWidth) * sliderItemWidth) * (-1) + 'px';
      }
      if (clickPositionStart === e.changedTouches[0].pageX) {
        window.location = e.target.href;
      }
      document.removeEventListener('touchend', touchEndHandler, false);
    });
  };

  return (
    <>
      <div className={'BrandSlider' + (className ? ' ' + className : '')}>
        {loading && <IconAndText icon={<Loader/>} text="Laden…"/>}
        {
          !loading && brands.length > 0 &&
          <div className={'slider-inner' + (transition ? ' transition' : '')} ref={slider}>
            {brands.map((brand, index) =>
              <a
                key={index} className="slider-item" href={'/produkte/' + brand.name.toLowerCase()}
                onClick={e => e.preventDefault()} onMouseDown={mouseDownHandler} onTouchStart={touchStartHandler}
              >
                {
                  brand.image?.filename &&
                  <span style={{backgroundImage: `url(${brand.image?.url})`}}/>
                }{
                  !brand.image?.filename &&
                  <span>{brand.name}</span>
                }
              </a>
            )}
          </div>
        }
      </div>
      <ErrorModal errors={errors} onDismiss={() => setErrors([])}/>
    </>
  );

}
