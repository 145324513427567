import "./checkbox.scss";
import {useField, useFormikContext} from "formik";
import {Check as CheckIcon} from "./icons";
import Error from './error';

export default function Checkbox({id, name, className, children, showErrorMessages = false}) {

  const [field, meta] = useField(name);
  const {setFieldValue, setFieldTouched} = useFormikContext();

  return (
    <div
      className={'Checkbox' + (meta.touched && meta.error ? ' has-error' : '') + (className ? ' ' + className : '')}
    >
      <div>
        <input
          id={id || name} type="checkbox" checked={field.value}
          onChange={() => {setFieldTouched(name); setFieldValue(name, !field.value);}}
        />
        <label htmlFor={id || name}><CheckIcon/></label>
      </div>
      <div>
        <label htmlFor={id || name}>{children}</label>
        {
          (showErrorMessages === true || (showErrorMessages === 'onDemand' && meta.touched && meta.error)) &&
          <Error>{meta.touched && meta.error}</Error>
        }
      </div>
    </div>
  );

}
