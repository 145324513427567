import Section from "../component/section";
import Overline from "../component/overline";
import Row from "../component/row";
import ImageAndTextCard from "../component/image-and-text-card";
import Login from "../component/login";

export default function Index() {
  return (
    <>
      <Section backgroundImage={{
        default: process.env.PUBLIC_URL + '/background-portrait.webp',
        md: process.env.PUBLIC_URL + '/background-landscape.webp'
      }}>
        <Row align="center" columns={{default: 1, lg: 2}}>
          <div>
            <Overline>Online-Shop</Overline>
            <h1>Unser Shop.<br/>Wie für Sie gemacht.</h1>
          </div>
          <Login/>
        </Row>
      </Section>
      <Section>
        <Overline>Herzlich Willkommen</Overline>
        <h2 className="narrow">Schauen Sie sich um. Überzeugen Sie sich selbst.</h2>
        <p>
          Melden Sie sich jetzt an. Denn nur als Kunde genießen Sie die vielen Vorteile, exklusiven Angebote, unsere
          Services und noch so einiges mehr – was unser Landewyck Shop zu bieten hat. Es lohnt sich. Überzeugen Sie sich
          selbst.
        </p>
      </Section>
      <Section>
        <Overline>Vorteile</Overline>
        <h2 className="narrow">Alle Vorteile. Alles auf einen Blick.</h2>
        <Row columns={{default: 1, md: 2, xl: 4}} className="mt-2">
          <ImageAndTextCard headline="24 Stunden geöffnet" image="clock.svg" imageHeight="4rem">
            Bestellen Sie rund um die Uhr und ganz bequem das gesamte Heintz van Landewyck Sortiment per Klick. Dazu
            erhalten Sie immer die neuesten Neueinführungen und Unternehmensinformationen.
          </ImageAndTextCard>
          <ImageAndTextCard headline="Beste Qualität" image="check.svg" imageHeight="4rem">
            Qualitativ hochwertige Produkte aus lokaler und europäischer Produktion. Und Tabak und Zigaretten in
            Westeuropa ausschließlich selbst. Dabei legen wir Wert auf höchste Herstellungs-, Arbeits- und
            Produktstandards.
          </ImageAndTextCard>
          <ImageAndTextCard headline="Beste Angebote" image="percentage.svg" imageHeight="4rem">
            Laufend exklusive online Aktionspakete mit attraktiven Vergütungskonditionen über alle Warengruppen
            hinweg. Die Angebote gibt’s nur exklusiv in unserem Shop.
          </ImageAndTextCard>
          <ImageAndTextCard headline="Jederzeit Verfügbar" image="download.svg" imageHeight="4rem">
            Erhalten Sie Zugriff auf den Kunden Download-Bereich, über den Sie die aktuellen Produktabbildungen,
            GTIN-Listen und das Gesamtprogramm jederzeit herunterladen können.
          </ImageAndTextCard>
        </Row>
      </Section>
    </>
  );
}
