import {Route, Routes} from "react-router-dom";
import Index from "./route";
import Footer from "./component/footer";
import Header from "./component/header";
import Impressum from "./route/impressum";
import NutzungsUndGeschaeftsbedingungen from "./route/nutzungs-und-geschaeftsbedingungen";
import Datenschutz from "./route/datenschutz";
import ScrollToTop from "./component/scroll-to-top";
import FixedButtonLink from "./component/fixed-button-link";
import {ExternalLink} from "./component/icons";
import NotFound from "./route/not-found";
import Support from "./route/support";
import Bonusclub from "./route/bonusclub";
import {useEffect, useState} from "react";
import useStorage from "./hook/useStorage";
import {AuthContext} from "./context/auth-context";
import Produkte from "./route/produkte";
import Aktionen from "./route/aktionen";
import Downloads from "./route/downloads";
import Anmelden from "./route/anmelden";
import Start from "./route/start";
import RequireAuth from "./component/require-auth";
import DenyAuth from "./component/deny-auth";
import Konto from "./route/konto";
import ResetPassword from "./route/reset-password";
import {PosContextProvider} from "./context/pos-context";
import {ShoppingCartContextProvider} from "./context/shopping-cart-context";
import Warenkorb from "./route/warenkorb";
import Bestellen from "./route/bestellen";
import ErrorModal from "./component/error-modal";
import useClaims from "./hook/useClaims";
import useBL from "./hook/useBL";
import Bestellungen from "./route/bestellungen";
import Bestellung from "./route/bestellung";
import DenySales from "./component/deny-sales";
// import InfoBoxDeliveryTime from "./component/info-box-delivery-time";
import Punktekonto from "./route/punktekonto";
import DenyCreditExcluded from "./component/deny-credit-excluded";

export default function App() {

  const storage = useStorage();
  const [authToken, setAuthToken] = useState(storage.local.get('authToken') ?? storage.session.get('authToken') ?? '');
  const claims = useClaims(authToken);
  const bl = useBL(authToken);
  const [errors, setErrors] = useState([]);
  // const [showInfoBoxDeliveryTime, setShowInfoBoxDeliveryTime] = useState(!!authToken);

  useEffect(() => {
    if (authToken) {
      const deleteAuthToken = () => {
        setAuthToken(null);
        storage.local.remove('authToken');
        storage.session.remove('authToken');
      };
      if (new Date(claims.exp).getTime() < new Date().getTime()) {
        deleteAuthToken();
      } else {
        bl.renew_auth_token()
          .then(token => {
            setAuthToken(token);
            if (storage.local.get('authToken')) {
              storage.local.set('authToken', token);
            } else {
              storage.session.set('authToken', token);
            }
          })
          .catch(errors => deleteAuthToken() || setErrors(errors));
      }
    }
  }, [authToken, bl, claims.exp, storage.local, storage.session]);

  return (
    <div className="App">
      <AuthContext.Provider value={{authToken, setAuthToken}}>
        <PosContextProvider>
          <ShoppingCartContextProvider>
            <ScrollToTop/>
            <Header/>
            <Routes>
              <Route path="" element={<DenyAuth><Index/></DenyAuth>}/>
              <Route path="/start" element={<RequireAuth><DenySales><Start/></DenySales></RequireAuth>}/>
              <Route path="/konto" element={<RequireAuth><Konto/></RequireAuth>}/>
              <Route path="/produkte" element={<RequireAuth><Produkte/></RequireAuth>}/>
              <Route path="/produkte/:brand" element={<RequireAuth><Produkte/></RequireAuth>}/>
              <Route
                path="/aktionen"
                element={<RequireAuth><DenyCreditExcluded><Aktionen/></DenyCreditExcluded></RequireAuth>}
              />
              <Route path="/downloads" element={<RequireAuth><Downloads/></RequireAuth>}/>
              <Route path="/support" element={<RequireAuth><Support/></RequireAuth>}/>
              <Route
                path="/bonusclub"
                element={<RequireAuth><DenyCreditExcluded><Bonusclub/></DenyCreditExcluded></RequireAuth>}
              />
              <Route path="/warenkorb" element={<RequireAuth><Warenkorb/></RequireAuth>}/>
              <Route path="/bestellen" element={<RequireAuth><Bestellen/></RequireAuth>}/>
              <Route path="/bestellungen" element={<RequireAuth><Bestellungen/></RequireAuth>}/>
              <Route path="/bestellungen/:id" element={<RequireAuth><Bestellung/></RequireAuth>}/>
              <Route path="/punktekonto" element={<RequireAuth><Punktekonto/></RequireAuth>}/>
              <Route path="/impressum" element={<Impressum/>}/>
              <Route path="/nutzungs-und-geschaeftsbedingungen" element={<NutzungsUndGeschaeftsbedingungen/>}/>
              <Route path="/datenschutz" element={<Datenschutz/>}/>
              <Route path="/anmelden" element={<DenyAuth><Anmelden/></DenyAuth>}/>
              <Route path="/passwort-zuruecksetzen/:token" element={<ResetPassword/>}/>
              <Route path="*" element={<NotFound/>}/>
            </Routes>
            {/*{
              showInfoBoxDeliveryTime &&
              <InfoBoxDeliveryTime onDismiss={() => setShowInfoBoxDeliveryTime(false)}/>
            }*/}
            <Footer/>
            <FixedButtonLink
              to="https://blog.landewyck.com/de" text="Zum Blog" icon={<ExternalLink/>} iconPosition="right"
              target="_blank"
            />
          </ShoppingCartContextProvider>
        </PosContextProvider>
      </AuthContext.Provider>
      <ErrorModal errors={errors} onDismiss={() => setErrors([])}/>
    </div>
  );

}
