import './button.scss';

export default function Button({
  text, icon, iconPosition = 'left', type = 'button', inverted = false, inline = false, disabled = false, color,
  onClick, className
}) {
  return (
    <button
      className={
        'Button' + (inverted ? ' inverted' : '') + (inline ? ' inline' : '') + (color ? ' ' + color : '') +
        (className ? ' ' + className : '')
      }
      type={type} disabled={disabled} onClick={onClick}
    >
      {icon && iconPosition === 'left' && <span className="icon">{icon}</span>}
      <span className="text">{text}</span>
      {icon && iconPosition === 'right' && <span className="icon">{icon}</span>}
    </button>
  );
}
