import './product-filter.scss';
import {Loader, X} from "./icons";
import Overline from "./overline";
import Button from "./button";
import {useEffect, useLayoutEffect, useRef, useState} from "react";
import useCRUD from "../hook/useCRUD";
import IconAndText from "./icon-and-text";
import ErrorModal from "./error-modal";
import {useParams} from "react-router-dom";
import useDeepCompareEffect from "use-deep-compare-effect";
import useClaims from "../hook/useClaims";

export default function ProductFilter({expanded, collapseFilter, setFilter}) {

  const productFilter = useRef();
  const params = useParams();
  const crud = useCRUD();
  const claims = useClaims();

  const [brands, setBrands] = useState([]);
  const [productTypes, setProductTypes] = useState([]);
  const [errors, setErrors] = useState([]);
  const [loading, setLoading] = useState(false);

  useLayoutEffect(() => {
    const size = () => {
      document.getElementsByTagName('html')[0].style.position =
        expanded && getComputedStyle(productFilter.current).position === 'fixed' ? 'fixed' : null;
    };
    size();
    window.addEventListener('resize', size);
    return () => window.removeEventListener('resize', size);
  }, [expanded]);

  useEffect(() => {
    const timeout = setTimeout(() => setLoading(true), 1000);
    let claimsFilter = [];
    if (claims.sales || claims.field_sales) {
      if (claims.sales) {
        claimsFilter.push(crud.filter.equals("is_sales", 1));
      }
      if (claims.field_sales) {
        claimsFilter.push(crud.filter.equals("is_field_sales", 1));
      }
    } else {
      claimsFilter.push(crud.filter.and(
        crud.filter.equals("is_sales", 0),
        crud.filter.equals("is_field_sales", 0)
      ));
    }
    Promise.all([
      crud.data.bulk.read({entity: 'brand', page_size: 100}),
      crud.data.bulk.read({entity: 'product_type', page_size: 100, filter: crud.filter.or(...claimsFilter)})
    ])
      .then(async ([brandResult, productTypeResult]) => {
        const [expandedBrands, expandedProductTypes] = await Promise.all([
          crud.expand(brandResult.items, ['image:file']),
          crud.expand(productTypeResult.items, ['symbol:file'])
        ]);
        setBrands(
          expandedBrands.map(brand => ({active: params.brand && brand.name.toLowerCase() === params.brand, ...brand}))
        );
        setProductTypes(
          expandedProductTypes.map(productType => ({active: false, ...productType}))
        );
      })
      .catch(errors => setErrors(errors))
      .finally(() => clearTimeout(timeout) || setLoading(false));
    return () => clearTimeout(timeout);
  }, [crud, params, claims]);

  useDeepCompareEffect(() => {
    let bf = brands.filter(b => b.active).map(brand => crud.filter.equals('brand_id', brand.id));
    bf = bf.length === 0 ? null : bf.length === 1 ? bf[0] : crud.filter.or(...bf);
    let ptf = productTypes.filter(pt => pt.active).map(pt => crud.filter.equals('product_type_id', pt.id));
    ptf = ptf.length === 0 ? null : ptf.length === 1 ? ptf[0] : crud.filter.or(...ptf);
    const filter = bf && ptf ? crud.filter.and(bf, ptf) : bf || ptf || {};
    setFilter && setFilter(filter);
  }, [brands, crud.filter, productTypes]);

  return (
    <>
      <div className={'ProductFilter' + (expanded ? ' expanded' : '')} ref={productFilter}>
        <div className="title"><span>Filter</span><button onClick={collapseFilter}><X/></button></div>
        <div className="body-wrapper">
          <div className="body">
            <Overline>Brands</Overline>
            {
              loading &&
              <IconAndText icon={<Loader/>} text="Laden…"/>
            }
            {
              !loading &&
              <ul className="brands">
                {
                  brands.map(
                    (brand, index) =>
                      <li
                        key={index} className={brand.active ? 'active' : null}
                        onClick={() => setBrands(brands.map(b => b === brand ? {...b, active: !b.active} : b))}
                      >
                        <img src={brand.image?.url} alt={brand.name}/>
                      </li>
                  )
                }
              </ul>
            }
            <Overline className="mt-2">Produkttypen</Overline>
            {
              loading &&
              <IconAndText icon={<Loader/>} text="Laden…"/>
            }
            {
              !loading &&
              <ul className="product-types">
                {
                  productTypes.map(
                    (productType, index) =>
                      <li
                        key={index} className={productType.active ? 'active' : null}
                        onClick={() =>
                          setProductTypes(productTypes.map(pt => pt === productType ? {...pt, active: !pt.active} : pt))
                        }
                      >
                        <img src={productType?.symbol?.url} alt=""/>
                        <span>{productType.name}</span>
                      </li>
                  )
                }
              </ul>
            }
          </div>
        </div>
        <div className="footer">
          <Button text="Schließen" onClick={collapseFilter}/>
        </div>
      </div>
      <ErrorModal errors={errors} onDismiss={() => setErrors([])}/>
    </>
  );

}
