import './section.scss';
import Container from "./container";

export default function Section({background, backgroundImage, className, children, noPadding}) {

  const style = {};
  if (backgroundImage) {
    style['--background-image-default'] = `url(${backgroundImage.default})`;
    style['--background-image-md'] = `url(${backgroundImage.md})`;
  }
  if (noPadding) {
    style.padding = 0;
  }

  return (
    <section
      className={
        'Section' +
        (background ? ' background' : '') +
        (backgroundImage ? ' background-image' : '') +
        (className ? ' ' + className : '')
      }
      style={Object.keys(style).length ? style : null}
    >
      <Container>{children}</Container>
    </section>
  );

}
