import Section from "../component/section";
import Overline from "../component/overline";
import Accordion from "../component/accordion";
import AccordionItem from "../component/accordion-item";
import {useState} from "react";
import ContactForm from "../component/contact-form";
import Download from "../component/download";
import PointsBanner from "../component/points-banner";

export default function Bonusclub() {

  const [showContactForm, setShowContactForm] = useState(false);

  return (
    <>
      <Section background>
        <Overline>Bonusclub</Overline>
        <h1 className="narrow">Punkte sammeln und Prämien erhalten</h1>
      </Section>
      <Section background>
        <PointsBanner/>
      </Section>
      <Section background>
        <h2>Handout &amp; Anbieterliste</h2>
        <Download
          className="mt-2"
          forceA
          download={
            {
              name: 'HvL Handout Bonusclub Prämien',
              file: {
                url: '/HvL_Handout_Bonusclub_Praemien_Lay01_RZ_28_08_24.pdf',
                size: 1592506
              }
            }
          }
        />
        <Download
          className="mt-1"
          forceA
          download={
            {
              name: 'Anbieterliste Gutscheine',
              file: {
                url: '/Anbieterliste_Gutscheine_Landewyck.pdf',
                size: 80944
              }
            }
          }
        />
      </Section>
      <Section background>
        <h2>Häufige Fragen zum Bonusclub</h2>
        <Accordion className="mt-2">
          <AccordionItem title="1) Was ist der Landewyck Bonusclub?">
            Der Landewyck Bonusclub wurde in Zusammenarbeit mit unserem Partner cadooz entwickelt und bietet unseren
            Kunden die Möglichkeit durch Einkäufe von entsprechend im Bonusclub gekennzeichneter Aktionen/Artikel für
            das jeweilige Einzelgeschäft Bonuspunkte zu sammeln und sie gegen eine Prämie einzulösen (siehe Punkt 5).
          </AccordionItem>
          <AccordionItem title="2) Wie kann man den  Landewyck Bonusclub nutzen?">
            <p>Um den Landewyck Bonusclub nutzen zu können, gelten folgende Voraussetzungen:</p>
            <ol>
              <li>Sie betreiben einen stationären Handel mit Tabakverkaufsstelle;</li>
              <li>Sie müssen als Einzelhandelskunde bei Landewyck gelistet sein; und</li>
              <li>Sie müssen für den HvL-Webshop als sog. Webshop-Kunde registriert sein.</li>
            </ol>
            <p>Landewyck steht dabei im Einzelfall das Recht zu, Kunden die Teilnahme zu verwehren.</p>
            <p>
              Sie Sind noch kein registrierter Webshop-Kunde? Kein Problem, senden Sie uns eine E-Mail über unser{' '}
              <button type="button" className="link" onClick={() => setShowContactForm(true)}> Kontaktformular</button>.
            </p>
          </AccordionItem>
          <AccordionItem title="3) Was kostet die Nutzung des Landewyck Bonusclubs?">
            Die Nutzung des Landewyck Bonusclubs ist kostenfrei.
          </AccordionItem>
          <AccordionItem title="4) Wie melde ich mich im Prämienshop an?">
            Das Einloggen im Prämienshop erfolgt problemlos über den HvL-Webshop.
          </AccordionItem>
          <AccordionItem title="5) Wie sammle ich Punkte?">
            Vorerst werden ausschließlich entsprechend im Bonusclub gekennzeichnete Aktionen/Artikel prämiert. Durch den
            Einkauf der gekennzeichneten Aktionen/Artikel sammeln Sie Bonuspunkte. Es werden ausschließlich
            Aktionen/Artikel prämiert, welche mit dem Landewyck Bonusclub Logo gekennzeichnet sind. <br/>
            <img
              src={process.env.PUBLIC_URL + '/landewyck-bonus-club.svg'} alt="Logo des Landewyck Bonus-Club"
              style={{width: '100px', margin: '1rem auto 0 0'}}
            />
          </AccordionItem>
          <AccordionItem title="6) Wann werden die Punkte gutgeschrieben?">
            Die zu erhaltenen Bonuspunkte werden nach Ablauf von 14 Tagen gutgeschrieben. Die Punktegutschrift erfolgt
            auf das Punktekonto des jeweiligen Einzelgeschäftes.
          </AccordionItem>
          <AccordionItem title="7) Kann ich meine gesammelten Punkte einsehen?">
            Ihre gesammelten Punkte pro Einzelgeschäft können Sie über Ihr HvL-Webshop-Konto einsehen.
          </AccordionItem>
          <AccordionItem title="8) Wie lange sind meine Punkte gültig?">
            Sollte innerhalb von 365 Tagen keine Bestellung für das jeweilige HvL-Webshop-Konto getätigt werden, fallen
            die Punkte auf Null zurück, verfallen also entsprechend.
          </AccordionItem>
          <AccordionItem title="9) Gibt es ein Punktelimit?">
            Das Punktelimit beträgt 10.000 Punkte pro Einzelgeschäft. Ist das Punktelimit pro Einzelgeschäft erreicht,
            werden keine neuen Punkte gutgeschrieben. Eine erneute Punktegutschrift ist erst nach Einlösen der Punkte
            wieder möglich.
          </AccordionItem>
          <AccordionItem title="10) Welche Benefits werden als Prämien angeboten?">
            Im Prämienshop werden Gutscheine von bekannten Top-Marken aus allen Produktbereichen in digitaler und
            physischer Form angeboten. Eine Liste der Anbieter finden Sie{' '}
            <a href="/Anbieterliste_Gutscheine_Landewyck.pdf" target="_blank">hier</a>.
          </AccordionItem>
          <AccordionItem title="11) Wen kontaktiere ich bei aufkommenden Problemen im Prämienshop?">
            <p>
              Bei Rückfragen zum Prämienshop oder aufkommenden Problemen im Rahmen der Benutzung des Prämienshops wenden
              Sie sich bitte an unseren Prämienshop-Kundenservice.
            </p>
            <p>
              E-Mail: <a href="mailto:landewyck@cadooz.de">landewyck@cadooz.de</a><br/>
              Telefonnummer: <a href="tel:+4940271482150">+49 40 271482150</a><br/>
              Servicezeiten: werktags 9-17 Uhr
            </p>
          </AccordionItem>
          <AccordionItem title="12) Wer ist cadooz?">
            cadooz rewards betreibt Prämienshops für Großkunden – wie z.B. Europas größtes Kundenbindungsprogramm – und
            bietet seit 2011 Full-Service-Leistungen für erfolgreiche Mitarbeiter- &amp; Kundenbindung verschiedenster
            Branchen. Von Beratung über Sourcing &amp; Aufbau zielgruppengerechter Sortimente bis zu Vermarktung &amp;
            Versand. Mit langjähriger Expertise &amp; einem starken Lieferantennetzwerk distribuiert cadooz rewards
            Top-Sachprämien an Kunden in ganz Europa.
          </AccordionItem>
          <AccordionItem title="13) Wie kann ich meine Landewyck Bonusclub Mitgliedschaft beenden?">
            Zum Beenden Ihrer Mitgliedschaft senden Sie uns eine E-Mail an{' '}
            <a href="mailto:landewyck@cadooz.de">landewyck@cadooz.de</a>.
          </AccordionItem>
        </Accordion>
      </Section>
      {showContactForm && <ContactForm onDismiss={() => setShowContactForm(false)}/>}
    </>
  );
}
