import './fixed-button-link.scss';
import ButtonLink from "./button-link";

export default function FixedButtonLink({text, icon, iconPosition, to, target}) {
  return (
    <div className="FixedButtonLink">
      <ButtonLink text={text} icon={icon} iconPosition={iconPosition} to={to} target={target}/>
    </div>
  );
}
