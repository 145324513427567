import "./order-item.scss";
import {displayPrice} from "../functions";
import {useEffect, useState} from "react";
import useCRUD from "../hook/useCRUD";
import ErrorModal from "./error-modal";
import IconAndText from "./icon-and-text";
import {Loader} from "./icons";

export default function OrderItem({item}) {
  const crud = useCRUD();
  const [placeholderImage, setPlaceholderImage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [errors, setErrors] = useState([]);

  useEffect(() => {
    if (!item.product?.image) {
      setIsLoading(true);
      crud.data.read({entity: 'file', id: item.product?.product_type?.placeholder_id})
        .then(placeholderResult => setPlaceholderImage(placeholderResult))
        .catch(errors => setErrors(errors))
        .finally(() => setIsLoading(false));
      setTimeout(() => setIsLoading(false), 5000);
    }
  }, [crud.data, item.product?.image, item.product?.product_type?.placeholder_id]);

  return (
    <div className="OrderItem">
      <div className="property">
        <div className="key">Bild:</div>
        <div className="value">
          {isLoading && <IconAndText icon={<Loader/>} text="Laden…" className="mt-1-75"/>}
          {!isLoading &&
          <>
            {item.product?.image && <img src={item.product?.image.url} alt=""/>}
            {item.promotion?.image && <img src={item.promotion?.image.url} alt=""/>}
            {
              !item.promotion && !item.product?.image && item.product?.product_type
              && placeholderImage
              && <img src={placeholderImage.url} alt=""/>
            }
          </>
          }
        </div>
      </div>
      <div className="property">
        <div className="key">Name:</div>
        <div className="value">{item.product?.name || item.promotion?.headline}</div>
      </div>
      <div className="property">
        <div className="key">Beschreibung:</div>
        <div className="value">{item.product?.description}</div>
      </div>
      <div className="property">
        <div className="key">Marke:</div>
        <div className="value">{item.product?.brand?.name}</div>
      </div>
      <div className="property">
        <div className="key">Anzahl:</div>
        <div className="value">{item.amount}</div>
      </div>
      <div className="property">
        <div className="key">Preis:</div>
        <div className="value">{displayPrice(item.product?.price || item.promotion?.price)}</div>
      </div>
      <div className="property">
        <div className="key">Gesammtsumme:</div>
        <div className="value">
          {displayPrice((item.product?.price * item.amount) || (item.promotion?.price * item.amount) || 0)}
        </div>
      </div>
      <ErrorModal errors={errors} onDismiss={() => setErrors([])}/>
    </div>
  );
}
