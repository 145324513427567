import React, {useContext, useEffect, useState} from "react";
import Modal from "./modal";
import {Form, Formik} from "formik";
import TextInput from "./text-input";
import Row from "./row";
import Button from "./button";
import {CircleCheck, Loader} from "./icons";
import ErrorModal from "./error-modal";
import * as Yup from "yup";
import {Link} from "react-router-dom";
import useBL from "../hook/useBL";
import TextAreaInput from "./textarea-input";
import Checkbox from "./checkbox";
import {AuthContext} from "../context/auth-context";
import useClaims from "../hook/useClaims";
import useCRUD from "../hook/useCRUD";
import IconAndText from "./icon-and-text";
import {PosContext} from "../context/pos-context";

export default function ContactForm({className, onDismiss}) {

  const bl = useBL();
  const authContext = useContext(AuthContext);
  const posContext = useContext(PosContext);
  const crud = useCRUD();
  const claims = useClaims();

  const [errors, setErrors] = useState([]);
  const [showSuccess, setShowSuccess] = useState(false);
  const [loading, setLoading] = useState(false);
  const [values, setValues] = useState({
    email: '',
    message: '',
    customerNumber: '',
    firstName: '',
    lastName: '',
    posName: '',
    street: '',
    postalCode: '',
    city: '',
    phone: '',
    privacy: false
  });

  let validations = {
    message: Yup.string()
      .required('Bitte füllen Sie dieses Pflichtfeld aus'),
    privacy: Yup.bool()
      .oneOf([true],'Bitte füllen Sie dieses Pflichtfeld aus')
  };
  if (!authContext.authToken) {
    validations = {
      email: Yup.string()
        .required('Bitte füllen Sie dieses Pflichtfeld aus')
        .email('Bitte geben Sie eine E-Mail-Adresse ein'),
      firstName: Yup.string()
        .required('Bitte füllen Sie dieses Pflichtfeld aus'),
      lastName: Yup.string()
        .required('Bitte füllen Sie dieses Pflichtfeld aus'),
      posName: Yup.string()
        .required('Bitte füllen Sie dieses Pflichtfeld aus'),
      street: Yup.string()
        .required('Bitte füllen Sie dieses Pflichtfeld aus'),
      postalCode: Yup.string()
        .required('Bitte füllen Sie dieses Pflichtfeld aus'),
      city: Yup.string()
        .required('Bitte füllen Sie dieses Pflichtfeld aus'),
      ...validations
    };
  }
  const validate = Yup.object(validations);

  const submit = (values, {setSubmitting}) => {
    bl.send_contact_form({
      email: values.email, message: values.message, customer_number: values.customerNumber,
      first_name: values.firstName, last_name: values.lastName, pos_name: values.posName, street: values.street,
      postal_code: values.postalCode, city: values.city, phone: values.phone
    })
      .then(() => setShowSuccess(true))
      .catch(errors => setErrors(errors))
      .finally(() => setSubmitting(false));
  };

  useEffect(() => {
    if (authContext.authToken) {
      setLoading(true);
      Promise.all([
        crud.data.read({entity: 'user', id: claims.user_id}),
        posContext.activePos ?
          crud.data.read({entity: 'address', id: posContext.activePos.invoice_address_id}) : Promise.resolve({}),
      ])
        .then(async ([user, address]) => {
          setValues({
            email: user.email ?? '',
            message: '',
            customerNumber: posContext.activePos?.customer_number ?? '',
            firstName: user.first_name ?? '',
            lastName: user.last_name ?? '',
            posName: address.name ?? '',
            street: address.street ?? '',
            postalCode: address.postal_code ?? '',
            city: address.city ?? '',
            phone: '',
            privacy: false
          });
        })
        .catch(errors => setErrors(errors))
        .finally(() => setLoading(false));
    }
  }, [claims.user_id, authContext.authToken, crud, posContext.activePos]);

  return (
    <Modal className={'ContactForm' + (className ? ' ' + className : '')} onDismiss={onDismiss} title="Kontaktformular">
      {loading && <IconAndText icon={<Loader/>} text="Laden…"/>}
      {
        !showSuccess && !loading &&
        <>
          <Formik
            enableReinitialize={true}
            initialValues={values}
            validationSchema={validate}
            onSubmit={submit}
            validate={() => setErrors([])
            }
          >
            {({isSubmitting}) => (
              <Form>
                <div style={authContext.authToken ? {display: 'none'} : null}>
                  <Row columns={{default: 1, sm: 2, lg: 2}} gap="1">
                    <TextInput name="customerNumber" label="HvL-Kundennummer"/>
                  </Row>
                </div>
                <div style={authContext.authToken ? {display: 'none'} : null}>
                  <Row columns={{default: 1, sm: 2, lg: 2}} gap="1">
                    <TextInput name="firstName" label="Vorname" required/>
                    <TextInput name="lastName" label="Nachname" required/>
                    <TextInput name="email" label="E-Mail Adresse" required/>
                    <TextInput name="phone" label="Telefon"/>
                  </Row>
                  <Row columns={{default: 1, sm: 2, lg: 2}} gap="1" className="mt-1">
                    <TextInput name="posName" label="Unternehmensname" required/>
                    <TextInput name="street" label="Straße / Hausnummer" required/>
                  </Row>
                  <Row columns={{default: 1, sm: 2, lg: 2}} gap="1" className="mt-1">
                    <TextInput name="postalCode" label="Postleitzahl" required/>
                    <TextInput name="city" label="Ort" required/>
                  </Row>
                </div>
                <TextAreaInput name="message" label="Nachricht" className="mt-1" required/>
                <Checkbox name="privacy" className="mt-1" showErrorMessages>
                  Mit der Nutzung dieses Formulars erklärst du dich mit der Speicherung deiner Kontaktdaten und dem
                  Umgang im Rahmen deines Anliegens gemäß unserer Datenschutzrichtlinie einverstanden.
                  Selbstverständlich kannst du deine Einwilligung jederzeit widerrufen. Nähere Informationen findest du
                  in der <Link to="/datenschutz" target="_blank"> Datenschutzerklärung</Link>.
                </Checkbox>
                <Row className="mt-1" justify="end" gap="1">
                  <Button text="Abbrechen" onClick={onDismiss} inverted inline/>
                  <Button
                    text="senden" type="submit" icon={isSubmitting && <Loader/>} disabled={isSubmitting}
                    inline iconPosition="right"
                  />
                </Row>
              </Form>
            )}
          </Formik>
          <ErrorModal errors={errors} onDismiss={() => setErrors([])}/>
        </>
      }
      {
        showSuccess && !loading &&
        <>
          <h3><CircleCheck/>Vielen Dank für Ihre Nachricht!</h3>
          <p className="text-right"><Button text="Schließen" onClick={onDismiss} inline/></p>
        </>
      }
    </Modal>
  );

}
