import './filter-sort-control.scss';
import Dropdown from './dropdown';
import DropdownItem from './dropdown-item';
import Button from './button';

export default function FilterSortControl({order, setOrder, expandFilter}) {

  const orderSelection = [
    {display: 'Name', orderBy: 'name', order: 'asc'},
    {display: 'Neu', orderBy: 'is_new', order: 'desc'},
    {display: 'Preis +++', orderBy: 'price', order: 'desc'},
    {display: 'Preis ---', orderBy: 'price', order: 'asc'}
  ];

  return (
    <div className="FilterSortControl">
      <Button text="Filter" onClick={expandFilter}/>
      <Dropdown
        selectedIndex={
          orderSelection.map(order => order.orderBy + '/' + order.order).indexOf(order.orderBy + '/' + order.order)
        }
      >
        {orderSelection.map((order, index) => (
          <DropdownItem key={index} onSelect={() => setOrder(order)}>{order.display}</DropdownItem>
        ))}
      </Dropdown>
    </div>
  );

}
