import './point-of-sale.scss';
import Abbreviation from "./abbreviation";
import Balance from "./balance";
import useShowCredits from "../hook/useShowCredits";

export default function PointOfSale({pointOfSale}) {

  const showCredits = useShowCredits();

  return (
    <div className="PointOfSale">
      <Abbreviation text={pointOfSale.abbreviation}/>
      {showCredits && <Balance balance={pointOfSale.balance}/>}
      <p className="small">
        {pointOfSale.shipping_address.street}<br/>
        {pointOfSale.shipping_address.postal_code} {pointOfSale.shipping_address.city}
      </p>
    </div>
  );
}
