import "./header.scss";
import {Link, NavLink, useLocation} from "react-router-dom";
import Nav from "./nav";
import Container from "./container";
import {HelpCircle, Menu, ShoppingCart as ShoppingCartIcon, User as UserIcon, X} from "./icons";
import DecoratedIcon from "./decorated-icon";
import {useContext, useLayoutEffect, useRef, useState} from "react";
import Dropdown from "./dropdown";
import DropdownItem from "./dropdown-item";
import PointOfSale from "./point-of-sale";
import {AuthContext} from "../context/auth-context";
import {PosContext} from "../context/pos-context";
import {ShoppingCartContext} from "../context/shopping-cart-context";
import useClaims from "../hook/useClaims";
import Balance from "./balance";
import useShowCredits from "../hook/useShowCredits";

export default function Header() {

  const headerRef = useRef();
  const secondDivRef = useRef();
  const authContext = useContext(AuthContext);
  const claims = useClaims();
  const posContext = useContext(PosContext);
  const shoppingCartContext = useContext(ShoppingCartContext);
  const [expanded, setExpanded] = useState(false);
  const location = useLocation();
  const showCredits = useShowCredits();

  useLayoutEffect(() => setExpanded(false), [location.pathname]);

  const keepBalanceWidth = () => {
    if (headerRef.current) {
      const balances = headerRef.current.querySelectorAll('.selection .Balance');
      if (balances.length > 0) {
        const maxWidth = Math.max(...[...balances].map(el => el.getBoundingClientRect().width));
        for (const balance of balances) {
          balance.style.width = `${maxWidth}px`;
        }
      }
    }
  };

  useLayoutEffect(() => {
    const size = () => {
      if (secondDivRef.current?.getBoundingClientRect()?.top > 50) {
        secondDivRef.current.style.margin = '0 auto';
      } else {
        secondDivRef.current.style.margin = '';
      }
    };
    setTimeout(size, 100);
    window.addEventListener('resize', size);
    return () => window.removeEventListener('resize', size);
  }, [posContext.loading]);

  return (
    <header className="Header" ref={headerRef}>
      <div>
        <Container>
          <Link to="/">
            <img src={process.env.PUBLIC_URL + '/landewyck-deutschland.svg'} alt="Landewyck Deutschland"/>
          </Link>
          <div ref={secondDivRef}>
            {
              authContext.authToken && !posContext.loading && posContext.pos.length > 1 &&
              <Dropdown
                selectedIndex={posContext.pos.map(pos => pos.id).indexOf(posContext.activePos.id)} listSelected
                onToggleExpanded={(expanded) => expanded && keepBalanceWidth()}
              >
                {
                  posContext.pos.map((pos, index) => (
                    <DropdownItem key={index} onSelect={() => posContext.setActivePos(pos)}>
                      <PointOfSale pointOfSale={pos}/>
                    </DropdownItem>
                  ))
                }
              </Dropdown>
            }
            <Nav inline>
              {
                authContext.authToken && !posContext.loading && posContext.pos.length === 1 && showCredits &&
                <Link to="/punktekonto">
                  <Balance balance={posContext.activePos.balance} color="blue" size="medium"/>
                </Link>
              }
              {
                (
                  (!claims.sales && !claims.field_sales)
                  || claims.online_sales
                )
                && <Link className="hidden-up-to-lg" to="/support">{<HelpCircle/>}</Link>
              }
              <Link className="hidden-up-to-lg" to="/konto">{<UserIcon/>}</Link>
              {
                authContext.authToken
                && (!claims.online_sales || claims.field_sales || claims.sales)
                && <Link to="/warenkorb">
                  <DecoratedIcon
                    icon={<ShoppingCartIcon/>}
                    content={
                      shoppingCartContext.shoppingCart.line_items.reduce(
                        (sum, lineItem) => sum + (lineItem.product_id || lineItem.promotion_id ? lineItem.amount : 0), 0
                      )
                    }
                  />
                </Link>
              }
              <button type="button" onClick={() => setExpanded(x => !x)}>{expanded ? <X/> : <Menu/>}</button>
            </Nav>
          </div>
          <Nav uppercase expanded={expanded}>
            {(!claims.online_sales || claims.field_sales || claims.sales) && <NavLink to="produkte">Produkte</NavLink>}
            {
              (!claims.online_sales || claims.field_sales || claims.sales)
              && <NavLink to="bestellungen">Bestellungen</NavLink>
            }
            {
              !claims.sales && !claims.field_sales && !claims.online_sales && showCredits &&
              <NavLink to="aktionen">Aktionen</NavLink>
            }
            {
              showCredits ? <NavLink to="bonusclub">Bonusclub</NavLink> : <NavLink to="downloads">Downloads</NavLink>
            }
            {
              (
                (!claims.sales && !claims.field_sales)
                || claims.online_sales
              ) && <NavLink to="support">Support</NavLink>
            }
            <NavLink to="konto">Konto</NavLink>
          </Nav>
        </Container>
      </div>
    </header>
  );

}
