import "./order-items.scss";
import OrderItem from "./order-Item";
import {useEffect, useState} from "react";
import useCRUD from "../hook/useCRUD";
import ErrorModal from "./error-modal";
import {useParams} from "react-router-dom";
import IconAndText from "./icon-and-text";
import {EmptyPackage, Loader} from "./icons";
import Pagination from "./pagination";
import ButtonLink from "./button-link";
import Abbreviation from "./abbreviation";
import {displayPrice, displayWholesaler} from "../functions";
import Overline from "./overline";

export default function OrderItems({className}) {

  const crud = useCRUD();
  const params = useParams();
  const pageSize = 10;
  const [totalLineItems, setTotalLineItems] = useState(0);
  const [page, setPage] = useState(0);
  const [loading, setLoading] = useState(false);
  const [showLoading, setShowLoading] = useState(false);
  const [errors, setErrors] = useState([]);
  const [order, setOrder] = useState(null);

  useEffect(() => {
    setLoading(true);
    setShowLoading(false);
    const timeout = setTimeout(() => setShowLoading(true), 1000);
    Promise.all([
      crud.data.bulk.read({
        entity: 'line_item', filter: crud.filter.equals('order_id', params.id), page_size: pageSize, page: page
      }),
      crud.data.read({entity: 'order', id: params.id})
    ])
      .then(([lineItemResult, orderResult]) => {
        setTotalLineItems(lineItemResult.total);
        Promise.all([
          crud.expand(lineItemResult.items, ['promotion', 'product'], orderResult.created_at),
          crud.data.read({
            entity: 'point_of_sale', id: orderResult.point_of_sale_id, given_time: orderResult.created_at
          }),
          orderResult.wholesaler_id ?
            crud.data.read({entity: 'wholesaler', id: orderResult.wholesaler_id, given_time: orderResult.created_at}) :
            {name: 'Direktabrechnung'}
        ])
          .then(async ([LineResultItems, posResult, wholesalerResult]) => {
            const [expansions, invoiceAddress, shippingAddress] = await Promise.all([
              crud.expand(
                LineResultItems.filter(lineItem => lineItem.product || lineItem.promotion).map(item =>
                  item.product ? {$entity: 'product', ...item.product} : {$entity: 'promotion', ...item.promotion}
                ),
                ['image:file', 'product_type', 'brand'], orderResult.created_at
              ),
              crud.data.read({entity: 'address', id: posResult.invoice_address_id, given_time: orderResult.created_at}),
              crud.data.read({entity: 'address', id: posResult.shipping_address_id, given_time: orderResult.created_at})
            ]);
            for (const item of LineResultItems) {
              for (const $entity of ['promotion', 'product']) {
                if (item[$entity]) {
                  const expansion = expansions.find(ex => ex.$entity === $entity && ex.id === item[$entity + '_id']);
                  delete expansion.$entity;
                  item[$entity] = expansion;
                }
              }
            }
            setOrder({
              ...orderResult, point_of_sale: posResult, wholesaler: wholesalerResult,
              invoice_address: invoiceAddress, shipping_address: shippingAddress, line_item: LineResultItems
            });
          })
          .finally(() => clearTimeout(timeout) || setShowLoading(false) || setLoading(false));
      })
      .catch(error => setErrors(error));
  }, [crud, page, params.id]);

  return (
    <div className={'OrderItems' + (className ? ' ' + className : '')}>
      {
        loading && showLoading &&
        <IconAndText icon={<Loader/>} text="Laden…" align="left"/>
      }
      {
        !loading && order?.line_item?.length === 0 &&
        <IconAndText icon={<EmptyPackage/>} text="Keine Bestellungen gefunden" align="left"/>
      }
      {
        order?.line_item?.length > 0 &&
        <>
          <div className="order-widget">
            <div>
              Bestellnummer
              <span>{order.id}</span>
            </div>
            <div>
              Datum
              <span>
                {new Date(order.created_at).toLocaleString(
                  'de-DE', {day: '2-digit', month: '2-digit', year: 'numeric', hour: '2-digit', minute: '2-digit'}
                )} Uhr
              </span>
            </div>
            <div>
              Großhändler
              <span>{displayWholesaler(order)}</span>
            </div>
            <div>
              Gesamtsumme
              <span>{displayPrice(order.total_price)}</span>
            </div>
          </div>
          <div className="order-widget">
            <div className="shop">
              Rechnungsadresse
              <span>{order.invoice_address.name}</span>
              <span>{order.invoice_address.street}</span>
              <span>{`${order.invoice_address.postal_code} ${order.invoice_address.city}`}</span>
            </div>
            <div className="shop">
              Geschäft
              <span><Abbreviation text={order.point_of_sale?.abbreviation}/></span>
            </div>
            <div className="shop">
              Lieferadresse
              <span>{order.shipping_address.name}</span>
              <span>{order.shipping_address.street}</span>
              <span>{`${order.shipping_address.postal_code} ${order.shipping_address.city}`}</span>
            </div>
          </div>
          <Overline>Bestellte Waren</Overline>
          <div className="header">
            <div/>
            <div>Produkt</div>
            <div>Beschreibung</div>
            <div>Marke</div>
            <div>Anzahl</div>
            <div>Preis</div>
            <div>Gesamt&shy;summe</div>
          </div>
          <div className="body">{
            order.line_item.map(
              (item, index) => <OrderItem item={item} key={index}/>
            )
          }</div>
        </>
      }
      {
        !loading && totalLineItems > order?.line_item?.length > 0 &&
        <Pagination className="mt-2" total={totalLineItems} page={page} pageSize={pageSize} setPage={setPage}/>
      }
      <ButtonLink text="Zurück" to={'/bestellungen'} inline className="mt-1"/>
      <ErrorModal errors={errors} onDismiss={() => setErrors([])}/>
    </div>
  );
}
