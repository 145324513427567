import './balance.scss';
import {default as BalanceImage} from '../image/balance';

export default function Balance({balance, size = 'small', color = 'white'}) {
  return (
    <span className={`Balance size-${size} color-${color}`}>
      <BalanceImage/>
      <span>{balance.toLocaleString()}</span>
    </span>
  );
}
