import "./orders.scss";
import useCRUD from "../hook/useCRUD";
import {useEffect, useState} from "react";
import IconAndText from "./icon-and-text";
import {EmptyPackage, Loader} from "./icons";
import Pagination from "./pagination";
import ErrorModal from "./error-modal";
import Order from "./order";
import {useNavigate} from "react-router-dom";

export default function Orders({className}) {

  const crud = useCRUD();
  const navigate = useNavigate();
  const pageSize = 10;
  const [orders, setOrders] = useState([]);
  const [totalOrders, setTotalOrders] = useState(0);
  const [page, setPage] = useState(0);
  const [loading, setLoading] = useState(false);
  const [showLoading, setShowLoading] = useState(false);
  const [errors, setErrors] = useState([]);

  useEffect(() => {
    setLoading(true);
    setShowLoading(false);
    const timeout = setTimeout(() => setShowLoading(true), 1000);
    crud.data.bulk.read({entity: 'order', page, page_size: pageSize, orderBy: 'created_at', order: 'desc'})
      .then(async ({items: orders, total: totalOrders}) => {
        Promise.all(orders.map(order => crud.expand([order], ['point_of_sale', 'wholesaler'], order.created_at)))
          .then(result => setOrders(result.map(order => order[0])));
        setTotalOrders(totalOrders);
      })
      .catch(errors => console.error(errors) || setErrors(errors))
      .finally(() => clearTimeout(timeout) || setShowLoading(false) || setLoading(false));
    return () => clearTimeout(timeout);
  }, [crud, page]);

  return (
    <div className={'Orders' + (className ? ' ' + className : '')}>
      {
        loading && showLoading &&
        <IconAndText icon={<Loader/>} text="Laden…" align="left"/>
      }
      {
        !loading && !orders.length &&
        <IconAndText icon={<EmptyPackage/>} text="Keine Bestellungen gefunden" align="left"/>
      }
      {
        orders.length > 0 &&
        <>
          <div className="header">
            <div>Bestellnummer</div>
            <div>Datum</div>
            <div>Geschäft</div>
            <div>Großhändler</div>
            <div>Gesamtsumme</div>
          </div>
          <div className="body">{
            orders.map((order, index) =>
              <Order order={order} key={index} onClick={order => navigate(`/bestellungen/${order.id}`)}/>
            )
          }</div>
        </>
      }
      {
        !loading && totalOrders > orders.length &&
        <Pagination className="mt-2" total={totalOrders} page={page} pageSize={pageSize} setPage={setPage}/>
      }
      <ErrorModal errors={errors} onDismiss={() => setErrors([])}/>
    </div>
  );

}
