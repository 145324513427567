import Overline from "../component/overline";
import Section from "../component/section";
import Credits from "../component/credits";
import useShowCredits from "../hook/useShowCredits";
import {Navigate} from "react-router-dom";

export default function Punktekonto() {

  const showCredits = useShowCredits();

  return (
    <>
      {
        showCredits &&
        <Section background>
          <Overline>Punktekonto</Overline>
          <Credits className="mt-1-75"/>
        </Section>
      }
      {
        !showCredits &&
        <Navigate to="/konto"/>
      }
    </>
  );

}
