import "./abbreviation_and_balance.scss";
import Abbreviation from "./abbreviation";
import Balance from "./balance";

export default function AbbreviationAndBalance({point_of_sales}) {
  return (
    <div className="AbbreviationAndBalance">
      <div className="beam">
        <Abbreviation text={point_of_sales.abbreviation}/>
        <Balance balance={point_of_sales.balance}/>
      </div>
      <div className="legend">
        <span>Bezeichnung</span>
        <span>Punkte</span>
      </div>
    </div>
  );
}
