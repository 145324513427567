import Section from "../component/section";
import {Link} from "react-router-dom";

export default function NutzungsUndGeschaeftsbedingungen() {
  return (
    <Section background>
      <h1>Nutzungs- und Geschäftsverbindungen</h1>
      <h2>1. Akzeptanz der Nutzungsbedingungen</h2>
      <p>
        Diese Website-Nutzungsbedingungen auf dieser Webseite („Nutzungsbedingungen“) regeln Ihre Nutzung dieser
        Website, einschließlich aller Seiten innerhalb von www.landewyck.com (im Folgenden zusammenfassend als „Website“
        bezeichnet). Diese Nutzungsbedingungen gelten in vollem Umfang für Ihren Zugang und Ihre Nutzung dieser Website
        und stellen dann eine rechtsverbindliche Vereinbarung zwischen Ihnen und der Landewyck Holding S.à.r.l.
        („Landewyck“, „uns“, „wir“ oder „unsere“) dar.
      </p>
        Bitte lesen Sie daher diese Nutzungsbedingungen sorgfältig durch, da Sie durch die Nutzung dieser Website
        ausdrücklich alle hierin enthaltenen Bedingungen in vollem Umfang akzeptieren und an diese gebunden sind. Wenn
        Sie Einwände gegen diese Nutzungsbedingungen haben oder mit ihnen nicht einverstanden sind, müssen Sie den
        Zugriff auf diese Website und deren Nutzung einstellen.
      <h2>2. Gesetzliches Alter</h2>
      <p>
        Die Nutzung dieser Website ist allen minderjährigen Personen (definiert als Personen, die nicht mindestens 18
        Jahre alt sind) untersagt. Sie dürfen diese Website nicht nutzen, wenn Sie minderjährig sind.
      </p>
      <h2>3. Geistige Eigentumsrechte</h2>
      <p>
        Landewyck Holding S.à.r.l., ihre Tochtergesellschaften und/oder ihre Lizenzgeber sind Eigentümer aller
        Materialien und geistigen Eigentumsrechte, die auf dieser Website verwendet und veröffentlicht werden. Dazu
        gehören insbesondere (aber nicht ausschließlich) Urheberrechte, Marken, Namen, Logos, Software, Datenbanken,
        Funktionalität, Website-Designs, Fotos, Illustrationen, Audio, Video und Text. Alle diese Rechte sind
        vorbehalten.
      </p>
      <p>
        Es wird Ihnen nur ein begrenztes Recht zur Nutzung dieser Website gewährt, das den in diesen Nutzungsbedingungen
        vorgesehenen Einschränkungen unterliegt.
      </p>
      <h2>4. Einschränkungen</h2>
      <p>
        Sie dürfen das Material und die Informationen, die auf dieser Website enthalten sind, nur für Ihren persönlichen
        und nicht-kommerziellen Gebrauch herunterladen und/oder ausdrucken.
      </p>
      <p>
        Sie sind ausdrücklich und nachdrücklich beschränkt auf:
      </p>
      <ul>
        <li>
          Material dieser Website in beliebigen Medien zu veröffentlichen
        </li>
        <li>
          Material dieser Website als Teil einer anderen Website zur Verfügung zu stellen, weder durch Hyperlinks,
          Framing oder andere Mittel
        </li>
        <li>
          Verkauf, Unterlizenzierung und/oder sonstige Kommerzialisierung von Material dieser Website
        </li>
        <li>
          diese Website in einer Weise zu nutzen, die dieser Website schadet oder schaden könnte
        </li>
        <li>
          Benutzung dieser Website auf eine Art und Weise, die den Zugang der Benutzer zu dieser Website beeinträchtigt
        </li>
        <li>
          Nutzung dieser Website für illegale Zwecke oder entgegen geltender Gesetze und Vorschriften
        </li>
        <li>
          Diese Website in einer Weise zu nutzen, die einer Person oder einem Unternehmen Schaden zufügt oder zufügen
          kann, insbesondere durch Senden, Kopieren oder Zulassen von verleumderischen, belästigenden oder obszönen
          Veröffentlichungen
        </li>
        <li>
          das Betreiben von Data-Mining, Data-Harvesting, Data-Extracting oder anderen ähnlichen Aktivitäten in Bezug
          auf diese Website oder während der Nutzung dieser Website
        </li>
        <li>
          Benutzung dieser Website zur Durchführung von Werbung oder Marketing
        </li>
      </ul>
      <h2>5. Gewährleistungsausschluss</h2>
      <p>
        Diese Website wird ausschließlich zur Bereitstellung allgemeiner Informationen über die Landewyck Holding
        S.à.r.l. und ihre Tochtergesellschaften betrieben. Sie wird Ihnen kostenlos und „wie besehen“ zur Verfügung
        gestellt, und zwar ohne ausdrückliche oder stillschweigende Zusicherungen oder Gewährleistungen jeglicher Art
        in Bezug auf diese Website, ihre Nutzung oder die auf ihr enthaltenen Materialien. Insbesondere übernehmen wir
        keine Gewähr für die Richtigkeit oder Verlässlichkeit der Informationen oder Aussagen, auf die auf dieser
        Website Bezug genommen wird, sowie für den Betrieb dieser Website, und nichts auf dieser Website darf als
        Angebots-, Verkaufs- oder Vertragsversprechen oder als Beratung verstanden werden.
      </p>
      <h2>6. Haftungsbeschränkung</h2>
      <p>
        Die Nutzung dieser Website erfolgt auf Ihr eigenes Risiko. In keinem Fall haften wir oder eine unserer Mutter-
        oder Tochtergesellschaften, verbundenen Unternehmen, leitenden Angestellten, Direktoren, Vertreter, Agenten und
        Mitarbeiter für Verluste oder Schäden oder irgendetwas, das sich aus Ihrem Zugang zu und/oder Ihrer Nutzung
        dieser Website ergibt, unabhängig davon, ob eine solche Haftung auf einem Vertrag, einer unerlaubten Handlung
        oder anderweitig beruht.
      </p>
      <p>
        Sie verzichten hiermit auf jegliche Ansprüche gegenüber uns, unseren Mutter- oder Tochtergesellschaften,
        verbundenen Unternehmen, leitenden Angestellten, Direktoren, Vertretern, Agenten und Mitarbeitern, die sich aus
        Ihrem Zugriff auf und/oder Ihrer Nutzung dieser Website und den darin enthaltenen Informationen oder Materialien
        ergeben oder in irgendeiner Weise damit zusammenhängen.
      </p>
      <h2>7. Schadensersatz</h2>
      <p>
        Sie verpflichten sich hiermit, die Landewyck Holding S.à.r.l. in vollem Umfang von jeglicher Haftung, Kosten,
        Ansprüchen, Klagegründen, Verlusten, Schäden und Ausgaben (einschließlich angemessener Anwalts- und allgemeiner
        Gerichtskosten) freizustellen, die sich aus der missbräuchlichen Nutzung dieser Website und/oder der Verletzung
        der Bestimmungen dieser Nutzungsbedingungen durch Sie ergeben oder in irgendeiner Weise damit zusammenhängen.
      </p>
      <h2>8. Datenschutzhinweis</h2>
      <p>
        Unsere <Link to="/datenschutz">Datenschutzerklärung</Link> ist von jeder Seite dieser Website aus zugänglich.
        Indem Sie auf diese Website zugreifen und sie nutzen, erklären Sie sich mit unseren Datenschutzbestimmungen
        einverstanden und erklären sich damit einverstanden, dass Sie ausreichende Informationen über die Verarbeitung
        Ihrer personenbezogenen Daten erhalten haben. Sollten Sie weitere Informationen wünschen, können Sie sich gemäß
        Abschnitt 14 an uns wenden.
      </p>
      <h2>9. Links zu externen Websites</h2>
      <p>
        Diese Website kann Links zu externen Websites oder Dienstleistungen enthalten, die sich nicht in unserem Besitz
        befinden oder von uns kontrolliert werden. Diese Links werden Ihnen nur aus Gründen der Bequemlichkeit zur
        Verfügung gestellt. Jede externe Website unterliegt ihren eigenen Geschäftsbedingungen und
        Datenschutzrichtlinien, daher empfehlen wir Ihnen, diese vor der Nutzung einer solchen Website zu konsultieren.
      </p>
      <p>
        Wir haben keine Kontrolle über und übernehmen keine Verantwortung für den Inhalt, die Geschäftsbedingungen, die
        Datenschutzrichtlinien oder andere Praktiken von externen Websites oder Diensten. Die Nutzung solcher externen
        Websites oder Dienste erfolgt auf Ihr eigenes Risiko. Sie erkennen an und erklären sich damit einverstanden,
        dass wir oder unsere Mutter- oder Tochtergesellschaften, verbundenen Unternehmen, leitenden Angestellten,
        Direktoren, Vertreter, Agenten und Mitarbeiter weder direkt noch indirekt für Verluste oder Schäden haften, die
        durch den Zugriff auf und/oder die Nutzung von oder das Vertrauen in Inhalte verursacht wurden oder angeblich
        verursacht wurden, die auf oder über solche Websites oder Dienste verfügbar sind.
      </p>
      <h2>10. Änderungen und Beendigung</h2>
      <p>
        Wir behalten uns das Recht vor, diese Nutzungsbedingungen jederzeit nach eigenem Ermessen und ohne vorherige
        Ankündigung zu überarbeiten, zu ändern oder zu ersetzen. Änderungen werden durch Veröffentlichung einer neuen
        aktualisierten Version der Nutzungsbedingungen bekannt gegeben. Durch die Nutzung dieser Website wird von Ihnen
        erwartet, dass Sie diese Nutzungsbedingungen regelmäßig überprüfen, um über Aktualisierungen informiert zu
        bleiben, und Sie erklären sich mit den Änderungen der überarbeiteten Nutzungsbedingungen einverstanden.
      </p>
      <p>
        Wir sind außerdem berechtigt, den Zugang zu dieser Website jederzeit ohne vorherige Ankündigung und ohne Haftung
        aus irgendeinem Grund auszusetzen, einzuschränken oder zu beenden, insbesondere (aber nicht beschränkt auf) den
        Fall, dass Sie gegen diese Nutzungsbedingungen verstoßen. Alle Bestimmungen der Nutzungsbedingungen, die ihrer
        Natur nach eine Beendigung überdauern sollten, bleiben bestehen, einschließlich (aber nicht beschränkt auf)
        geistige Eigentumsrechte, Gewährleistungsausschluss, Haftungsbeschränkungen und Entschädigung.
      </p>
      <h2>11. Streitbarkeit</h2>
      <p>
        Sollte sich eine Bestimmung dieser Nutzungsbedingungen nach geltendem Recht als nicht durchsetzbar oder ungültig
        erweisen, so bleiben alle anderen Bestimmungen davon unberührt und behalten ihre volle Gültigkeit und Wirkung.
        Die nicht durchsetzbare(n) oder ungültige(n) Bestimmung(en) gilt/gelten als ungültig und wird/werden durch eine
        rechtsgültige Bestimmung(en) ersetzt, die der/den ersetzten Bestimmung(en) am ehesten entspricht/entsprechen (in
        der gleichen Art und Weise und im gleichen Schema).
      </p>
      <h2>12. Gesamte Vereinbarung</h2>
      <p>
        Diese Nutzungsbedingungen, einschließlich aller rechtlichen Hinweise und Haftungsausschlüsse, die auf dieser
        Website enthalten sind, stellen die gesamte Vereinbarung zwischen der Landewyck Holding S.à.r.l. und Ihnen in
        Bezug auf die Nutzung dieser Website dar und ersetzen alle früheren Vereinbarungen und Absprachen in Bezug auf
        diese Website.
      </p>
      <h2>13. Geltendes Recht &amp; Gerichtsstand</h2>
      <p>
        Diese Nutzungsbedingungen und alle Streitigkeiten, die sich aus oder im Zusammenhang mit dieser Website und
        ihrem gesamten Inhalt ergeben, unterliegen den Gesetzen des Großherzogtums Luxemburg und werden in
        Übereinstimmung mit diesen ausgelegt. Für die Beilegung von Streitigkeiten sind ausschließlich die Gerichte von
        Luxemburg (Stadt) zuständig.
      </p>
      <h2>14. Kontakt</h2>
      <p>
        Für Fragen, Informationen, Anfragen, Beschwerden oder jegliche Art von Korrespondenz im Zusammenhang mit diesen
        Nutzungsbedingungen oder Inhalten dieser Website können Sie uns entweder über den Bereich „Kontakt“ auf dieser
        Website kontaktieren oder uns eine E-Mail an die folgenden Adressen senden:
      </p>
      <p>
        E-Mail: <a href="mailto:personaldata@lag.lu">personaldata@lag.lu</a>
      </p>
      <p>
        Brief: Zu Händen des Personal Data Board Landewyck Holding S.à.r.l. – 31, rue de Hollerich – L-1741 Luxemburg –
        Großherzogtum Luxemburg.
      </p>
    </Section>
  );
}
