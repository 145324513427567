import './accordion.scss';
import React, {useState} from "react";

export default function Accordion({className, children}) {

  const [open, setOpen] = useState();

  return (
    <div className={'Accordion' + (className ? ' ' + className : '')}>
      {
        React.Children.toArray(children)
          .map((child, index) => {
            return React.cloneElement(
              child, {index, open: index === open, setOpen: () => setOpen(index === open ? undefined : index)}
            );
          })
      }
    </div>
  );

}
