import {useMemo} from "react";

const prefix = 'landewyck-shop_';

export default function useStorage() {
  return useMemo(() => ({
    local: storage(window.localStorage),
    session: storage(window.sessionStorage)
  }), []);
}

function storage(storage) {
  return {
    get: key => {
      let value = null;
      try {
        value = JSON.parse(storage.getItem(prefix + key));
      } catch (error) {
        console.error(error);
      }
      return value;
    },
    set: (name, value) => {
      try {
        storage.setItem(prefix + name, JSON.stringify(value));
      } catch (error) {
        console.error(error);
      }
    },
    remove: name => {
      try {
        storage.removeItem(prefix + name);
      } catch (error) {
        console.error(error);
      }
    }
  };
}
