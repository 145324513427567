import "./credits.scss";
import {useContext} from "react";
import CreditTransactions from "../component/credit-transactions";
import Abbreviation from "../component/abbreviation";
import {PosContext} from "../context/pos-context";
import {EmptyPackage, Loader} from "./icons";
import IconAndText from "../component/icon-and-text";
import Balance from "./balance";

export default function Credits () {

  const posContext = useContext(PosContext);

  return (
    <div className="Credit">
      {
        posContext.loading &&
        <IconAndText icon={<Loader/>} text="Laden…" align="left"/>
      }
      {
        !posContext.loading && !posContext.activePos &&
        <IconAndText icon={<EmptyPackage/>} text="Kein Geschäft gefunden" align="left"/>
      }
      {
        !posContext.loading && posContext.activePos &&
        <>
          <div className="credit-widget">
            <div className="balance">
              Verfügbare Punkte
              <Balance balance={posContext.activePos.balance} size="big" color="blue"/>
            </div>
          </div>
          <div className="credit-widget">
            <div className="shop">
              Rechnungsadresse
              <span>{posContext.activePos.invoice_address.name}</span>
              <span>{posContext.activePos.invoice_address.street}</span>
              <span>
                {`${posContext.activePos.invoice_address.postal_code} ${posContext.activePos.invoice_address.city}`}
              </span>
            </div>
            {
              !posContext.loading && posContext.pos.length > 1 &&
              <div className="shop">
                Geschäft
                <span><Abbreviation text={posContext.activePos.abbreviation}/></span>
              </div>
            }
            <div className="shop">
              Lieferadresse
              <span>{posContext.activePos.shipping_address.name}</span>
              <span>{posContext.activePos.shipping_address.street}</span>
              <span>
                {`${posContext.activePos.shipping_address.postal_code} ${posContext.activePos.shipping_address.city}`}
              </span>
            </div>
          </div>
          <h3 className="table-headline">Gültige Buchungen</h3>
          <CreditTransactions/>
          <h3 className="table-headline">Erwartete Buchungen</h3>
          <CreditTransactions future/>
        </>
      }
    </div>
  );

}
