import "./dropdown.scss";
import React, {useLayoutEffect, useRef, useState} from "react";
import {Arrow} from "./icons";

export default function Dropdown({
  children, selectedIndex = 0, listSelected = false, align = 'right', className, onToggleExpanded
}) {

  const [expanded, setExpanded] = useState(false);
  const dropdownRef = useRef();
  const selectionRef = useRef();

  useLayoutEffect(() => {
    onToggleExpanded && onToggleExpanded(expanded);
    const maintainHeightAndPosition = () => {
      if (expanded) {
        const childBcr = selectionRef.current.childNodes[0].getBoundingClientRect();
        const parentBcr = selectionRef.current.parentNode.getBoundingClientRect();
        selectionRef.current.style.height = childBcr.height + 'px';
        if (align === 'right') {
          selectionRef.current.style.left = parentBcr.x + parentBcr.width - childBcr.width <= 0 ? 0 : null;
        } else {
          selectionRef.current.style.left = 0;
        }
      } else {
        selectionRef.current.style.height = 0;
      }
    };
    maintainHeightAndPosition();
    const maybeClose = e => expanded && e.target.closest('.Dropdown') !== dropdownRef.current && setExpanded(false);
    window.addEventListener('click', maybeClose);
    window.addEventListener('resize', maintainHeightAndPosition);
    return () => {
      window.removeEventListener('click', maybeClose);
      window.removeEventListener('resize', maintainHeightAndPosition);
    };
  }, [align, expanded, onToggleExpanded]);

  return (
    <div
      className={
        'Dropdown' + (expanded ? ' expanded' : '') + (children.length < 2 ? ' empty' : '') +
        (className ? ' ' + className : '')
      }
      ref={dropdownRef}
    >
      <div className="selected-item" onClick={() => setExpanded(expanded => !expanded)}>
        {React.cloneElement(children[selectedIndex], {onSelect: () => {}})}
        <span className="icon"><Arrow orientation={expanded ? 'up' : 'down'}/></span>
      </div>
      <div className="selection" ref={selectionRef}>
        <ul>
          {
            children.map((child, index) => (
              !listSelected && index === selectedIndex ? null :
                <li key={index}>
                  {React.cloneElement(
                    child, {onSelect: () => {setExpanded(false); child.props.onSelect();}}
                  )}
                </li>
            ))
          }
        </ul>
      </div>
    </div>
  );
}
