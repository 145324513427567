import Section from "../component/section";
import Overline from "../component/overline";
import Accordion from "../component/accordion";
import AccordionItem from "../component/accordion-item";
import {useState} from "react";
import ContactForm from "../component/contact-form";

export default function Support() {

  const [showContactForm, setShowContactForm] = useState(false);

  return (
    <>
      <Section background>
        <Overline>Support</Overline>
        <h1 className="narrow">Auf uns können Sie sich verlassen. Egal, worum es geht.</h1>
      </Section>
      <Section background>
        <h2>Häufige Fragen</h2>
        <Accordion className="mt-2">
          <AccordionItem title="Welche sind die leichtesten Tabak- und Zigarettensorten?">
            Unsere leichtesten Tabaksorten sind: Ducal gold, Elixyr blau und Fargo blau. In der Kategorie Zigaretten
            sind unsere besonders leichten Sorten: Ducal silber, Ducal blau, Ducal gold und Elixyr gold.
          </AccordionItem>
          <AccordionItem title="Wie sind die Verpackungseinheiten der Feinschnitt-Produkte?">
            Die Stopfbeutel Verpackungseinheiten sind fast alle im Gebinde à 10 Beutel. Nur die Marke Maya wird im
            Gebinde à 5 Beutel verpackt. Bei den Filtertips sind jeweils 8 Displays im Karton mit jeweils 10 Beuteln à
            100 Stück verpackt.
          </AccordionItem>
          <AccordionItem title="Welche Zahlungsmöglichkeiten habe ich im Landewyck-Online-Shop?">
            Die Zahlungsmöglichkeiten sind analog zur klassischen Bestellung über die bekannten Kanäle. Wenn die
            Abrechnungsmethode bisher über einen ausgewählten Großhändler abgewickelt wurde, bleibt dies auch im
            Online-Shop bestehen. Ist die Abrechnung bisher über Landewyck gelaufen, verändert sich diese Zahlungsweise
            ebenso nicht. Wenn Sie die Abrechnungsmethode ändern möchtest, können Sie das jederzeit unserem
            Kundenservice-Team unter <a href="tel:+496518265127">+49 (0) 651/8265-127</a> mitteilen.
          </AccordionItem>
          <AccordionItem title="Kann ich meinen Auftrag nach der Bestellung noch ändern oder stornieren?">
            Sie können den Auftrag jederzeit ändern oder stornieren. Um das zu tun, melden Sie sich bitte
            unter <a href="tel:+496518265127">+49 (0) 651/8265-127</a> bei unserem Kundenservice-Team.
          </AccordionItem>
          <AccordionItem title="Welche Versandkosten kommen auf mich zu?">
            Der Versand ist für Sie kostenlos.
          </AccordionItem>
          <AccordionItem title="Wer hilft mir weiter, wenn ich hier keine Antwort auf meine Frage finde?">
            Sollten Sie weitere Fragen haben, haben Sie mehrere Möglichkeiten mit uns in Kontakt zu treten. Schreiben
            Sie gerne eine Mail über unser Kontaktformular, schicken Sie ihre Frage direkt im Chat oder melden Sie sich
            telefonisch bei unserem Kundenservice unter <a href="tel:+496518265127">+49 (0) 651/8265-127</a>. Sie
            benötigen eine detaillierte Beratung? Dann vereinbare Sie jetzt ihren persönlichen Termin über die
            Termin-Vereinbarungs-Funktion.
          </AccordionItem>
          <AccordionItem title="Sie möchten ein gekauftes Produkt retournieren? wir helfen ihnen gerne weiter!">
            Wenn Sie ein bestelltes Produkt zurückgeben möchten, wenden Sie sich bitte direkt an unseren Kundenservice
            unter <a href="tel:+496518265127">+49 (0) 651/8265-127</a> oder schreiben Sie uns eine Mail
            an <a href="mailto:bestellung@landewyck.de">bestellung@landewyck.de</a>.
          </AccordionItem>
        </Accordion>
      </Section>
      {showContactForm && <ContactForm onDismiss={() => setShowContactForm(false)}/>}
    </>
  );
}
