import "./order.scss";
import Abbreviation from "./abbreviation";
import {displayPrice, displayWholesaler} from "../functions";

export default function Order({order, onClick = () => {}}) {
  return (
    <div className="Order" onClick={() => onClick(order)}>
      <div className="property">
        <div className="key">Bestellnummer:</div>
        <div className="value">{order.id}</div>
      </div>
      <div className="property">
        <div className="key">Datum:</div>
        <div className="value">
          {new Date(order.created_at).toLocaleString(
            'de-DE', {day: '2-digit', month: '2-digit', year: 'numeric', hour: '2-digit', minute: '2-digit'}
          )} Uhr
        </div>
      </div>
      <div className="property">
        <div className="key">Geschäft:</div>
        <div className="value"><Abbreviation text={order.point_of_sale?.abbreviation}/></div>
      </div>
      <div className="property">
        <div className="key">Großhändler:</div>
        <div className="value">{displayWholesaler(order)}</div>
      </div>
      <div className="property">
        <div className="key">Gesamtsumme:</div>
        <div className="value">{displayPrice(order.total_price)}</div>
      </div>
    </div>
  );

}
