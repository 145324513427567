import './download.scss';
import ButtonLink from "./button-link";
import {readableDataSize} from "../functions";

export default function Download({download, className, forceA = false}) {

  return (
    <div className={'Download' + (className ? ' ' + className : '')}>
      <a href={download.file.url} target="_blank" rel="noreferrer"><h3>{download.name}</h3></a>
      <span>{download.file.url.split('.').reverse()[0]}, {readableDataSize(download.file.size)}</span>
      <ButtonLink text="Download" to={download.file.url} forceA={forceA} target="_blank" inline/>
    </div>
  );

}
