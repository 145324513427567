import {useParams} from "react-router-dom";
import {useEffect, useState} from "react";
import * as Yup from "yup";
import {Form, Formik} from "formik";
import useBL from "../hook/useBL";
import PasswordInput from "../component/password-input";
import Button from "../component/button";
import {Loader} from "../component/icons";
import ButtonLink from "../component/button-link";
import Section from "../component/section";
import ErrorModal from "../component/error-modal";
import Row from "../component/row";

export default function ResetPassword() {

  const params = useParams();
  const bl = useBL();

  const [errors, setErrors] = useState([]);
  const [showSuccess, setShowSuccess] = useState(false);

  const validationSchema = Yup.object({
    password: Yup.string()
      .required('Bitte ein Passwort eingeben'),
    passwordRepetition: Yup.string()
      .required('Bitte das Passwort wiederholen')
      .oneOf([Yup.ref('password')], 'Passwörter müssen übereinstimmen')
  });

  const submit = (values, {setSubmitting}) => {
    bl.reset_password({token: params.token, password: values.password})
      .then(() => setShowSuccess(true))
      .catch(errors => setErrors(errors))
      .finally(() => setSubmitting(false));
  };

  useEffect(() => {
    bl.validate_password_reset_token({token: params.token}).catch(errors => setErrors(errors));
  }, [bl, params.token]);

  return (
    <Section>
      <h1>Passwort zurücksetzen</h1>
      {
        !showSuccess &&
          <>
            <p>
              Bitte ein neues Passwort eingeben. Das Passwort muss mindestens acht Zeichen lang sein, einen Groß-, einen
              Kleinbuchstaben und eine Ziffer beinhalten.
            </p>
            <Formik
              initialValues={{password: '', passwordRepetition: ''}} validationSchema={validationSchema}
              onSubmit={submit} validate={() => setErrors([])}
            >
              {({isSubmitting}) => (
                <Form>
                  <PasswordInput name="password" placeholder="Passwort"/>
                  <PasswordInput name="passwordRepetition" placeholder="Passwort wiederholen"/>
                  <Row className="mt-1" gap="1">
                    <ButtonLink text="Abbrechen" to="/anmelden" inverted inline/>
                    <Button
                      text="Absenden" type="submit" icon={isSubmitting && <Loader/>} iconPosition="right" inline
                      disabled={isSubmitting}
                    />
                  </Row>
                </Form>
              )}
            </Formik>
            <ErrorModal errors={errors} onDismiss={() => setErrors([])}/>
          </>
      }
      {
        showSuccess &&
          <>
            <p>Das neue Passwort kann nun für die Anmeldung verwendet werden.</p>
            <p>
              <ButtonLink text="Zur Anmeldung" to="/anmelden" inline/>
            </p>
          </>
      }
    </Section>
  );
}
