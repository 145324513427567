import Overline from "../component/overline";
import Section from "../component/section";
import Orders from "../component/orders";

export default function Bestellungen() {

  return (
    <Section background>
      <Overline>Bestellungen</Overline>
      <Orders className="mt-1-75"/>
    </Section>
  );
}
