import './price.scss';
import {displayPrice} from "../functions";

export default function Price({amount, info, align, className}) {
  return (
    <div className={'Price' + (align ? ` align-${align}` : '') + (className ? ' ' + className : '')}>
      {displayPrice(amount)}<span>{info}</span>
    </div>
  );
}
