import {useContext, useMemo} from "react";
import {AuthContext} from "../context/auth-context";

export default function useClaims(authToken) {

  const authContext = useContext(AuthContext);

  return useMemo(() => {

    let claims = {};

    if (authToken || authContext.authToken) {
      const segments = (authToken || authContext.authToken).split('.');
      if (segments.length >= 2) {
        try {
          claims = JSON.parse(atob(segments[1].replace('-', '+').replace('_', '/')));
        } catch (error) {
          console.error(error);
        }
      }
    }

    return claims;

  }, [authToken, authContext.authToken]);

}
