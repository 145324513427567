import './row.scss';

export default function Row({className, align, justify, children, gap, columns, style = {}}) {

  if (gap) {
    style.gap = gap + 'rem';
  }
  Object.entries(columns ?? {}).forEach(([size, value]) => {
    style[`--columns-${size}`] = `0 0 calc(${100 / value}% - ${(gap ?? 1.75) * (1 - 1 / value)}rem)`;
  });

  return (
    <div
      className={
        'Row' + (className ? ' ' + className : '') + (align ? ` align-${align}` : '') +
        (justify ? ` justify-${justify}` : '')
      }
      style={Object.keys(style).length > 0 ? style : null}
    >
      {children}
    </div>
  );

}
