import Login from "../component/login";
import Section from "../component/section";

export default function Anmelden() {
  return (
    <Section background>
      <h1>Anmelden</h1>
      <Login className="mt-2"/>
    </Section>
  );
}
