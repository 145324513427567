import React from "react";
import './button.scss';
import {Link} from "react-router-dom";

export default function ButtonLink({
  text, icon, iconPosition = 'left', inverted = false, to, target, color, onClick, inline = false, className,
  forceA = false
}) {
  return (
    <>
      {
        !to.match(/^http/) && !forceA &&
        <Link
          to={to} className={'Button' + (inverted ? ' inverted' : '') + (inline ? ' inline' : '')
          + (color ? ' ' + color : '')  + (className ? ' ' + className : '')} onClick={onClick}
        >
          {icon && iconPosition === 'left' && <span className="icon">{icon}</span>}
          <span className="text">{text}</span>
          {icon && iconPosition === 'right' && <span className="icon">{icon}</span>}
        </Link>
      }
      {
        (to.match(/^http/) || forceA) &&
        <a
          href={to} className={'Button' + (inverted ? ' inverted' : '') + (inline ? ' inline' : '')
          + (color ? ' ' + color : '') + (className ? ' ' + className : '')} target={target}
          rel={target === '_blank' ? 'noreferrer' : null}
        >
          {icon && iconPosition === 'left' && <span className="icon">{icon}</span>}
          <span className="text">{text}</span>
          {icon && iconPosition === 'right' && <span className="icon">{icon}</span>}
        </a>
      }
    </>
  );
}
