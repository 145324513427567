import "./nav.scss";
import {useLayoutEffect, useRef} from "react";

export default function Nav({inline = false, uppercase = false, expanded, children}) {

  const nav = useRef();

  useLayoutEffect(() => {
    if (typeof expanded !== 'undefined') {
      const size = () => {
        nav.current.style.height = expanded ? nav.current.childNodes[0].getBoundingClientRect().height + 'px' : 0;
      };
      size();
      window.addEventListener('resize', size);
      return () => window.removeEventListener('resize', size);
    }
  }, [expanded]);

  return (
    <nav
      className={
        'Nav' + (inline ? ' inline' : '') + (uppercase ? ' uppercase' : '') +
        (typeof expanded !== 'undefined' ? ' expandable' : '')
      }
      ref={nav}
    >
      <ul>
        {children.map((child, index) => <li key={index}>{child}</li>)}
      </ul>
    </nav>
  );

}
