import Modal from './modal';
import './error-modal.scss';

export default function ErrorModal({errors, onDismiss}) {

  if (errors instanceof Error) {
    console.error(errors);
    errors = [errors];
  }

  return (
    <Modal className="ErrorModal" title="Fehler" show={errors.length > 0} onDismiss={onDismiss}>
      <p>
        {errors.length === 1 && 'Der Vorgang kann aus folgendem Grund nicht ausgeführt werden:'}
        {errors.length !== 1 && 'Der Vorgang kann aus folgenden Gründen nicht ausgeführt werden:'}
      </p>
      <ul>
        {errors.map((error, index) => <li key={index}>{error.message}</li>)}
      </ul>
    </Modal>
  );

}
