import './image-and-text-card.scss';
import {Link} from "react-router-dom";

export default function ImageAndTextCard({image, headline, href, imageHeight, children}) {
  return (
    <div className="ImageAndTextCard">
      <img src={process.env.PUBLIC_URL + '/' + image} alt="" style={imageHeight ? {height: imageHeight} : null}/>
      <h3>{headline}</h3>
      <p>{children}</p>
      {href && <Link to={href}>mehr erfahren</Link>}
    </div>
  );
}
