import "./login.scss";
import * as Yup from "yup";
import {Form, Formik} from "formik";
import TextInput from "./text-input";
import PasswordInput from "./password-input";
import Checkbox from "./checkbox";
import Button from "./button";
import {useLocation, useNavigate} from "react-router-dom";
import {Loader} from "./icons";
import useBL from "../hook/useBL";
import {useContext, useState} from "react";
import {AuthContext} from "../context/auth-context";
import useStorage from "../hook/useStorage";
import ErrorModal from "./error-modal";
import RequestPasswordRecovery from "./request-password-recovery";
import LinkButton from "./link-button";
import ContactForm from "./contact-form";

export default function Login({className}) {

  const bl = useBL();
  const navigate = useNavigate();
  const location = useLocation();
  const storage = useStorage();
  const authContext = useContext(AuthContext);
  const [showContactForm, setShowContactForm] = useState(false);
  const [errors, setErrors] = useState([]);
  const [showPasswordReset, setShowPasswordReset] = useState(false);

  const submit = (values, {setSubmitting}) => {
    bl.login({username_or_email: values.username_or_email, password: values.password})
      .then(authToken => {
        authContext.setAuthToken(authToken);
        if (values.stayLoggedIn) {
          storage.local.set('authToken', authToken);
        } else {
          storage.session.set('authToken', authToken);
        }
        navigate(location.state?.from?.pathname || '/start', {replace: true});
      })
      .catch(errors => setErrors(errors))
      .finally(() => setSubmitting(false));
  };

  const validationSchema = Yup.object({
    username_or_email: Yup.string().required('Bitte füllen Sie dieses Pflichtfeld aus'),
    password: Yup.string().required('Bitte füllen Sie dieses Pflichtfeld aus')
  });

  return (
    <>
      {showPasswordReset && <RequestPasswordRecovery onDismiss={() => setShowPasswordReset(false)}/>}
      <div className={'Login' + (className ? ' ' + className : '')}>
        <h3>Willkommen</h3>
        <p>Um den Landewyck-Shop zu betreten, geben Sie bitte Ihre Shop-Zugangsdaten ein.</p>
        <Formik
          initialValues={{username_or_email: '', password: '', stayLoggedIn: true}} onSubmit={submit}
          validationSchema={validationSchema}
        >
          {({isSubmitting}) => (
            <Form>
              <TextInput name="username_or_email" label="E-Mail-Adresse" required/>
              <PasswordInput name="password" label="Passwort" required/>
              <Checkbox name="stayLoggedIn">Angemeldet bleiben</Checkbox>
              <Button
                type="submit" text={isSubmitting ? 'Bitte warten…' : 'Anmelden'} icon={isSubmitting && <Loader/>}
                disabled={isSubmitting} className="mt-025"
              />
            </Form>
          )}
        </Formik>
        <p className="mt-1">
          <LinkButton text="Passwort neu vergeben" onClick={() => setShowPasswordReset(true)}/>
        </p>
        <p>
          Noch kein Konto?
          <LinkButton
            text="Jetzt einen Termin mit unserem Kundenservice vereinbaren."
            onClick={() => setShowContactForm(true)}
          />
        </p>
      </div>
      {showContactForm && <ContactForm onDismiss={() => setShowContactForm(false)}/>}
      <ErrorModal errors={errors} onDismiss={() => setErrors([])}/>
    </>
  );
}
