import Section from "../component/section";
import {useContext, useEffect, useState} from "react";
import {ShoppingCartContext} from "../context/shopping-cart-context";
import Overline from "../component/overline";
import useCRUD from "../hook/useCRUD";
import ErrorModal from "../component/error-modal";
import IconAndText from "../component/icon-and-text";
import {CircleCheck, EmptyPackage, Loader} from "../component/icons";
import LineItem from "../component/line-item";
import Widget from "../component/widget";
import Price from "../component/price";
import Row from "../component/row";
import Button from "../component/button";
import {PosContext} from "../context/pos-context";
import DottedCard from "../component/dotted-card";
import Abbreviation from "../component/abbreviation";
import LabelAndText from "../component/label-and-text";
import * as Yup from "yup";
import {Form, Formik} from "formik";
import {Link} from "react-router-dom";
import Checkbox from "../component/checkbox";
import useBL from "../hook/useBL";
import Dropdown from "../component/dropdown";
import DropdownItem from "../component/dropdown-item";
import useClaims from "../hook/useClaims";
import useShowCredits from "../hook/useShowCredits";

export default function Bestellen() {

  const shoppingCartContext = useContext(ShoppingCartContext);
  const posContext = useContext(PosContext);
  const crud = useCRUD();
  const claims = useClaims();
  const bl = useBL();
  const showCredits = useShowCredits();
  const [lineItems, setLineItems] = useState([]);
  const [shoppingCartLoading, setShoppingCartLoading] = useState(false);
  const [posLoading, setPosLoading] = useState(false);
  const [pos, setPos] = useState();
  const [availableWholesalerOptions, setAvailableWholesalerOptions] = useState(
    (claims.sales || claims.field_sales) ?
      ['Direktabrechnung'] : ['Bitte auswählen', 'Direktabrechnung', 'Abrechnung über Großhändler']
  );
  const [selectedWholesalerOption, setSelectedWholesalerOption] = useState(
    (claims.sales || claims.field_sales) ? 'Direktabrechnung' : 'Bitte auswählen'
  );
  const [wholesalerError, setWholesalerError] = useState(false);
  const [errors, setErrors] = useState([]);
  const [success, setSuccess] = useState(false);
  const [creditsEarned, setCreditsEarned] = useState(0);

  // Auflistung der Großhändler, die mit dem ausgewählten Geschäft verknüpft sind
  const [loadingWholesalers, setLoadingWholesalers] = useState(false);
  const [wholesalers, setWholesalers] = useState([]);
  const [selectedWholesalerId, setSelectedWholesalerId] = useState(null);
  useEffect(() => {
    const timeout = setTimeout(() => setLoadingWholesalers(true), 1000);
    if (posContext.activePos) {
      bl.get_wholesalers_for_order({point_of_sale_id: posContext.activePos.id})
        .then(wholesalers => {
          setWholesalers([{id: null, name: "Bitte auswählen"}, ...wholesalers]);
          setSelectedWholesalerId(null);
          if (wholesalers.length === 0) {
            setAvailableWholesalerOptions(['Direktabrechnung']);
            setSelectedWholesalerOption('Direktabrechnung');
          } else {
            setAvailableWholesalerOptions((claims.sales || claims.field_sales) ?
              ['Direktabrechnung'] : ['Bitte auswählen', 'Abrechnung über Großhändler', 'Direktabrechnung']);
            setSelectedWholesalerOption((claims.sales || claims.field_sales) ? 'Direktabrechnung' : 'Bitte auswählen');
          }
          setWholesalerError(false);
        })
        .catch(errors => setErrors(errors))
        .finally(() => clearTimeout(timeout) || setLoadingWholesalers(false));
    }
    return () => clearTimeout(timeout);
  }, [bl, claims.field_sales, claims.sales, posContext.activePos]);

  const validationSchema = Yup.object({
    tos: Yup.bool().oneOf([true], 'Bitte füllen Sie dieses Pflichtfeld aus')
  });

  const validateWholesaler = () => {
    const wholesalerError = (
      selectedWholesalerOption === 'Bitte auswählen' ||
      (selectedWholesalerOption !== 'Direktabrechnung' && selectedWholesalerId === null)
    );
    setWholesalerError(wholesalerError);
    return wholesalerError;
  };

  const scrollTo = (id) => {
    const yOffset = -80;
    const element = document.getElementById(id);
    const y = element.getBoundingClientRect().top + window.scrollY + yOffset;
    window.scrollTo({top: y, behavior: 'smooth'});
  };

  const submit = (values, {setSubmitting}) => {
    if (!validateWholesaler()) {
      bl.order({
        point_of_sale_id: pos.id,
        shopping_cart_id: shoppingCartContext.shoppingCart.id,
        wholesaler_id: selectedWholesalerId,
        custom_wholesaler: selectedWholesalerOption === 'Direktabrechnung' ? 'Direktabrechnung' : null
      })
        .then(
          () => {
            setCreditsEarned(shoppingCartContext.shoppingCart.credits);
            setSuccess(true);
            shoppingCartContext.newShoppingCart();
          }
        )
        .catch((errors) => setErrors(errors))
        .finally(() => setSubmitting(false));
    } else {
      setSubmitting(false);
    }
  };

  useEffect(() => {
    const timeout = setTimeout(() => setPosLoading(true), 1000);
    if (posContext.activePos) {
      crud.expand([posContext.activePos], ['shipping_address:address', 'invoice_address:address'])
        .then(([expandedPos]) => setPos(expandedPos))
        .catch(errors => setErrors(errors))
        .finally(() => clearTimeout(timeout) || setPosLoading(false));
    }
    return () => clearTimeout(timeout);
  }, [crud, posContext.activePos]);

  useEffect(() => {
    setShoppingCartLoading(true);
    crud.expand(shoppingCartContext.shoppingCart.line_items, ['promotion', 'product'])
      .then(async lineItems => {
        const expansions = await crud.expand(
          lineItems.filter(lineItem => lineItem.product || lineItem.promotion).map(lineItem =>
            lineItem.product ? {$entity: 'product', ...lineItem.product} : {$entity: 'promotion', ...lineItem.promotion}
          ),
          ['image:file', 'product_type', 'brand']
        );
        for (const lineItem of lineItems) {
          for (const $entity of ['promotion', 'product']) {
            if (lineItem[$entity]) {
              const expansion = expansions.find(ex => ex.$entity === $entity && ex.id === lineItem[$entity + '_id']);
              delete expansion.$entity;
              lineItem[$entity] = expansion;
            }
          }
        }
        // NR-Produkte nach hinten sortieren
        lineItems.sort((a, b) => {
          // Prüfen, ob a und b die Eigenschaft "product.free_good" haben
          const aFreeGood = a?.product?.free_good === '1';
          const bFreeGood = b?.product?.free_good === '1';
          // Wenn a ein "free_good" ist und b nicht, sortiere b vor a
          if (aFreeGood && !bFreeGood) {
            return 1;
          }
          // Wenn b ein "free_good" ist und a nicht, sortiere a vor b
          if (bFreeGood && !aFreeGood) {
            return -1;
          }
          // Wenn beide gleich sind, bleibt die Reihenfolge unverändert
          return 0;
        });
        setLineItems(lineItems);
      })
      .catch(errors => setErrors(errors))
      .finally(() => setShoppingCartLoading(false));
  }, [crud, shoppingCartContext.shoppingCart]);

  return (
    <>
      <Section background>
        <Overline>Kasse</Overline>
        <h1 className="narrow">Bestellung prüfen</h1>
      </Section>
      {
        success &&
        <Section>
          <h3><CircleCheck/>Vielen dank für Ihr Vertrauen!</h3>
          <p>
            Ihre Bestellung ist bei uns eingegangen und wird so schnell wie möglich bearbeitet. In kürze erhalten Sie
            eine Bestellbestätigung per E-Mail.
          </p>
          {
            showCredits && creditsEarned > 0 &&
            <p>Die Punkte aus dieser Bestellung werden Ihnen nach 14 Tagen gutgeschrieben.</p>
          }
        </Section>
      }
      {
        !success &&
        <>
          {posLoading && <Section><IconAndText icon={<Loader/>} text="Laden…"/></Section>}
          {
            !posLoading && pos &&
            <Section>
              <Row columns={{default: 1, sm: 2, lg: 3}}>
                <DottedCard>
                  <Widget>
                    {posContext.pos.length > 1 && <div><Abbreviation text={pos.abbreviation}/></div>}
                    <LabelAndText label="Lieferadresse" className={posContext.pos.length > 1 ? 'mt-1' : null}>
                      {pos.shipping_address.name && <>{pos.shipping_address.name}<br/></>}
                      {pos.shipping_address.street}<br/>
                      {pos.shipping_address.postal_code} {pos.shipping_address.city}
                    </LabelAndText>
                    {
                      !claims.sales && !claims.field_sales &&
                      <LabelAndText label="Rechnungsadresse" className="mt-1">
                        {pos.invoice_address.name && <>{pos.invoice_address.name}<br/></>}
                        {pos.invoice_address.street}<br/>
                        {pos.invoice_address.postal_code} {pos.invoice_address.city}
                      </LabelAndText>
                    }
                  </Widget>
                </DottedCard>
              </Row>
              {
                !claims.sales && !claims.field_sales &&
                <>
                  <div id="wholesaler" className="mt-2 color-secondary">Abrechnung:</div>
                  <Dropdown
                    align="left"
                    className="mt-025"
                    selectedIndex={availableWholesalerOptions.indexOf(selectedWholesalerOption)}
                  >
                    {
                      availableWholesalerOptions.map(
                        (wholesalerOption, index) => (
                          <DropdownItem key={index} onSelect={
                            () => {
                              setSelectedWholesalerOption(wholesalerOption);
                              if (wholesalerOption === 'Direktabrechnung') {
                                if (!wholesalers.some(w => w.id === null)) {
                                  setWholesalers([{ id: null, name: "Bitte auswählen" }, ...wholesalers]);
                                }
                                setSelectedWholesalerId(null);
                              }
                              if (wholesalerOption !== 'Bitte auswählen') {
                                setAvailableWholesalerOptions(['Direktabrechnung', 'Abrechnung über Großhändler']);
                                setWholesalerError(false);
                              }
                            }}
                          >
                            {wholesalerOption}
                          </DropdownItem>
                        ))
                    }
                  </Dropdown>
                  {
                    !loadingWholesalers && wholesalers.length > 0
                    && selectedWholesalerOption === 'Abrechnung über Großhändler' &&
                    <>
                      <div className="mt-2 color-secondary">Großhändler:</div>
                      <Dropdown
                        align="left"
                        className="mt-025"
                        selectedIndex={wholesalers.findIndex(w => w.id === selectedWholesalerId)}
                      >
                        {
                          wholesalers.map(
                            (wholesaler, index) => (
                              <DropdownItem key={index} onSelect={() => {
                                setSelectedWholesalerId(wholesaler.id);
                                if (wholesaler.id !== null) {
                                  setWholesalers(wholesalers.filter(w => w.id !== null));
                                  setWholesalerError(false);
                                }
                              }}>
                                {wholesaler.name}
                              </DropdownItem>
                            ))
                        }
                      </Dropdown>
                    </>
                  }
                  <div className="Error mt-1" style={!wholesalerError ? {visibility: 'hidden'} : {}}>
                    Bitte füllen Sie dieses Pflichtfeld aus
                  </div>
                </>
              }
            </Section>
          }
          {shoppingCartLoading && <Section background><IconAndText icon={<Loader/>} text="Laden…"/></Section>}
          {
            !shoppingCartLoading && !lineItems.length &&
            <Section background><IconAndText icon={<EmptyPackage/>} text="Ihr Warenkorb ist leer"/></Section>
          }
          {!shoppingCartLoading && lineItems.length > 0 && (
            <Section background>
              <div className="LineItem description">
                <div className="image"/>
                <div className="text">
                  <div className="title">Produkt</div>
                  <div className="unit-price">Preis</div>
                  <div className="total-price">Gesamtsumme</div>
                  <div className="AmountSelection">Anzahl</div>
                </div>
              </div>
              {lineItems.filter(lineItem => lineItem.promotion).map((lineItem, index) => (
                <LineItem key={index} lineItem={lineItem}/>
              ))}
              {lineItems.filter(lineItem => lineItem.product).map((lineItem, index) => (
                <LineItem key={index} lineItem={lineItem}/>
              ))}
              {
                showCredits &&
                <Widget className="color-secondary text-right bt">
                  {'Für diesen Einkauf erhalten Sie '}
                  {
                    shoppingCartContext.shoppingCart.credits === 0 &&
                    ' keine '
                  }
                  {
                    shoppingCartContext.shoppingCart.credits > 0 &&
                    <span className="highlight-text">{shoppingCartContext.shoppingCart.credits}</span>
                  }
                  {' Punkte.'}
                </Widget>
              }
              <Row columns={{default: 1, md: 2}} justify="end">
                <div className="mt-1">
                  {
                    !claims.sales && !claims.field_sales &&
                    <>
                      <p className="mt-1 small">
                        Hinweis: Die tatsächliche Gesamtsumme richtet sich nach Ihren individuellen Konditionen.
                      </p>
                      {
                        showCredits && shoppingCartContext.shoppingCart.credits > 0 &&
                        <p className="small">
                          Die Punkte aus dieser Bestellung werden Ihnen nach 14 Tagen gutgeschrieben.
                        </p>
                      }
                    </>
                  }
                  <Formik
                    initialValues={{tos: false}} onSubmit={submit} validationSchema={validationSchema}
                    validate={validateWholesaler}
                  >
                    {({isSubmitting, getFieldMeta}) => (
                      <Form>
                        <Widget>
                          <Row gap={.25} columns={{default: 2, md: 2}} justify="center">
                            <span className="color-secondary"><strong>Gesamtsumme</strong></span>
                            <Price
                              amount={
                                lineItems.reduce(
                                  (sum, lineItem) =>
                                    sum + lineItem.amount * (
                                      parseInt(lineItem?.product?.price ?? 0) +
                                      parseInt(lineItem?.promotion?.price ?? 0)
                                    ),
                                  0
                                )
                              }
                              align="right"
                            />
                          </Row>
                          <p id="tos" className="mt-1" style={{hyphens: 'auto'}}>
                            Wir verwenden Ihre personenbezogenen Daten, um Ihre Bestellung durchführen zu können, eine
                            möglichst gute Benutzererfahrung auf dieser Website zu ermöglichen und für weitere Zwecke,
                            die in unserer <Link to="/datenschutz" target="_blank">Datenschutzerklärung </Link>
                            beschrieben sind.
                          </p>
                          <Checkbox id="foo" name="tos" className="mt-1" showErrorMessages="onDemand">
                            Ich habe die <Link to="/nutzungs-und-geschaeftsbedingungen" target="_blank">
                            Geschäftsbedingungen </Link> gelesen und stimmte ihnen zu.
                          </Checkbox>
                        </Widget>
                        <Button
                          type="submit" disabled={isSubmitting} icon={isSubmitting ? <Loader/> : null}
                          iconPosition="right"
                          text={
                            isSubmitting ?
                              'Bitte warten…'
                              : (
                                !claims.sales && !claims.field_sales
                                  ? 'Zahlungspflichtig bestellen'
                                  : 'Kostenlos bestellen'
                              )
                          }
                        />
                        <div
                          className="Error mt-1" style={!wholesalerError ? {display: 'none'} : {cursor: 'pointer'}}
                          onClick={() => scrollTo('wholesaler')}
                        >
                          Bitte füllen Sie die Angaben zur Abrechnung aus
                        </div>
                        <div
                          className="Error mt-1"
                          style={
                            !getFieldMeta('tos').touched || !getFieldMeta('tos').error ?
                              {display: 'none'} : {cursor: 'pointer'}
                          }
                          onClick={() => scrollTo('tos')}
                        >
                          Bitte lesen Sie die Geschäftsbedingungen und stimmen Sie zu
                        </div>
                      </Form>
                    )}
                  </Formik>
                </div>
              </Row>
            </Section>
          )}
        </>
      }
      <ErrorModal errors={errors} onDismiss={() => setErrors([])}/>
    </>
  );

}
