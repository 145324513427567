import {useEffect, useState} from "react";
import useBL from "../hook/useBL";
import config from "../config.json";
import ErrorModal from "./error-modal";
import ButtonLink from "./button-link";

export default function RedeemPointsButton({point_of_sales, inline = false}) {

  const [displayWaitMessage, setDisplayWaitMessage] = useState(false);
  const [cadoozUrl, setCadoozUrl] = useState('');
  const [errors, setErrors] = useState([]);
  const bl = useBL();

  useEffect(() => {

    let isMounted = true;

    const updateCadoozUrl = displayWaitMessage => {
      setDisplayWaitMessage(displayWaitMessage);
      bl.get_cadooz_code({point_of_sale_id: point_of_sales.id})
        .then(code => isMounted && setCadoozUrl(config.CADOOZ_URL + '?code=' + code))
        .catch(errors => isMounted && setErrors(errors))
        .finally(() => isMounted && setDisplayWaitMessage(false));
    };

    updateCadoozUrl(true);
    const interval = setInterval(() => updateCadoozUrl(false), 150000); // 150.000ms = 2min30s

    return () => {
      isMounted = false;
      clearInterval(interval);
    };

  }, [bl, point_of_sales.id]);

  return (
    <>
      <ButtonLink
        inline={inline} text={displayWaitMessage ? 'Bitte warten…' : 'Punkte einlösen'} color="primary" to={cadoozUrl}
        target="_blank"
      />
      <ErrorModal errors={errors} onDismiss={() => setErrors([])}/>
    </>
  );

}
